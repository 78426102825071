import React from 'react';
import './style.css';
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { ParaSyle, CIrcleDiv, IconDiv } from './footer-style';
import { Link } from 'react-router-dom';
export default function Footer() {
  return (
    <div className="footer">
      <Box sx={CIrcleDiv}>
        <img
          style={{ width: '50%' }}
          src={require('../../assets/images/hootLogo.png')}
          alt="HootDex"
        />
      </Box>
      <div style={{ backgroundColor: '#1c2221', width: '100vw' }}>
        <Box>
          <p style={{ color: 'green', fontWeight: 'bold' }}>
            BUILT ON A CARBON NEUTRAL BLOCKCHAIN NETWORK 🌱
          </p>
        </Box>
        <Box className="footer-main">
          <Box
            sx={{
              width: { xs: '100%', sm: '100%', md: '30%', lg: '22%' },
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              flex: 0.3
            }}
          >
            <img
              style={{ width: '50%' }}
              src={require('../../assets/images/logov.png')}
              alt="HootDex"
            />
            <Box
              style={{
                width: '100%',
                fontSize: '14px',
                textAlign: 'left',
                color: '#686F7E'
              }}
            >
              HootDex : A Decentralized Digital Asset Swapping System
            </Box>
          </Box>
          <Box className="footer-link" style={{ flex: 0.4 }}>
            <Box className="footer-link-div">
              <Link to="/dashboard" className="footer-link">
                Dashboard
              </Link>
              <a href="" target="_blank" className="footer-link">
                Traders
              </a>
              <a
                href="https://www.hootdex.com/support/"
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                Support
              </a>
              <a
                href="https://www.hootdex.com/education/"
                target="_blank"
                className="footer-link"
                rel="noreferrer"
              >
                Educenter
              </a>
              <a
                href="  https://www.pecunovus.com/get-your-wallet/"
                target="_blank"
                className="footer-link"
              >
                Pecu Wallet
              </a>
            </Box>
            {/* <Box className="footer-link-icons">
              <Box sx={IconDiv}>
                <img src={require('../../assets/images/facebook.png')} />
              </Box>
              <Box sx={IconDiv}>
                <img src={require('../../assets/images/twitter.png')} />
              </Box>
              <Box sx={IconDiv}>
                {' '}
                <img src={require('../../assets/images/linkedin.png')} />
              </Box>
            </Box> */}
          </Box>
          <Box style={{ flex: 0.3, marinLeft: '60px' }}>
            <h2 style={{ textAlign: 'left' }}>Contact Us</h2>
            <Box className="contactus-container">
              {/* <Box sx={{ display: 'flex', gap: '20px' }}>
                <img src={require('../../assets/images/telephone 1.png')} />
                01472528369
              </Box> */}
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <img src={require('../../assets/images/mail 1.png')} />
                media@hootdex.com
              </Box>
              {/* <Box sx={{ display: 'flex', gap: '10px' }}>
            <img src={require('../../assets/images/location 1.png')} />
            4517 Washington Ave. Manchester, Kentucky 39495
          </Box> */}
            </Box>
          </Box>
        </Box>
        <Box className="footer-legals">
          <Box>© MegaHoot, LLC. All rights reserved</Box>
          <Box className="legals-list">
            <a
              href="https://www.hootdex.com/privacy/"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.hootdex.com/risk-disclosure/"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              Risk Disclosure
            </a>
            <a
              href="https://www.hootdex.com/legal/"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              Legal Disclaimer
            </a>
            <a
              href="https://www.hootdex.com/aml-and-kyc-compliance/"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              AML/KYC
            </a>
          </Box>
        </Box>
      </div>
    </div>
  );
}

import { api as axios } from '../../api';
import { useEffect, useState } from 'react';
import { HistoricalChart } from './api';
import { Line } from 'react-chartjs-2';
import { CircularProgress } from '@material-ui/core';
import SelectButton from './selectedButton';
import { chartDays, chartDays2 } from './chartDays';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import url from '../../serverUrl';
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import { Box, Skeleton, Button } from '@mui/material';
ChartJS.register(...registerables);
const ToolTipStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingTop: '20px',
  width: '200px',
  height: '250px',
  color: 'green',
  backgroundRepeat: 'no-repeat',
  backgroundImage:
    "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='199' height='145' viewBox='0 0 199 145' fill='none'><path d='M12.2578 14.5664C12.2578 11.2527 14.9441 8.56641 18.2578 8.56641H180.886C184.2 8.56641 186.886 11.2527 186.886 14.5664V110.011C186.886 113.325 184.2 116.011 180.886 116.011H111.928C110.341 116.011 108.851 116.776 107.926 118.066L101.116 127.566C100.331 128.662 98.7004 128.662 97.9147 127.566L91.1051 118.066C90.1803 116.776 88.6903 116.011 87.103 116.011H18.2578C14.9441 116.011 12.2578 113.325 12.2578 110.011V14.5664Z' fill='white'/></svg>\")"
};
const formatDuration = (label) => {
  const durationMap = {
    Hours: 'H',
    days: 'd',
    Days: 'd',
    Months: 'm',
    Year: 'y',
    Years: 'y'
  };

  const parts = label.split(' ');

  let formattedDuration = '';

  for (let i = 0; i < parts.length; i += 2) {
    const value = parseInt(parts[i], 10);
    const unit = durationMap[parts[i + 1]];

    if (!isNaN(value) && unit) {
      formattedDuration += `${value}${unit} `;
    }
  }

  return formattedDuration.trim();
};
const GraphTop = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  margin: { xs: '0', sm: '0', md: '20px 20px', lg: '20px 20px' }
};

const ConstGraphBtn = {
  color: '#686F7E',
  textAlign: 'center',
  fontFeatureSettings: "'salt' on, 'liga' off",
  fontFamily: 'Poppins',
  fontSize: {
    xs: '10px !important',
    md: '12px !important'
  },
  fontStyle: 'normal',
  fontWeight: '400',
  textTransform: 'none',
  width: { xs: '20px', sm: '25px', md: '30px', lg: '40px' }
};

const ConstGraphActiveBtn = {
  color: 'black',
  width: { xs: '30px', sm: '25px', md: '50px', lg: '50px' },
  'border-radius': '40px',
  background: '#76C8A8',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: {
    xs: '10px !important',
    md: '12px !important'
  },
  textTransform: 'none',
  fontFeatureSettings: "'salt' on, 'liga' off"
};

const CoinInfo = ({ coin, firstData, style }) => {
  const [historicData, setHistoricData] = useState([]);
  const symbol = coin?.symbol;
  const [days, setDays] = useState(1);
  const currency = 'USD';
  const [flag, setflag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState(1);
  const getChatData = (target) => {
    if (!symbol) {
      return;
    }
    setLoading(true);
    setHistoricData([]);
    const chartUrl = `${url}/wallet/get_change_token_${target}?symbol=${symbol}`;
    axios
      .get(chartUrl)
      .then((res) => {
        setflag(true);
        let hoursData = timeMappers();

        setHistoricData(res.data?.sort((a, b) => a.chart_date - b.chart_date));
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally((e) => setLoading(false));
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { Price, chart_date } = payload[0].payload; // Extracting data from the payload

      return (
        <div className="custom-tooltip" style={ToolTipStyle}>
          <span>{`Price: ${Price}`}</span>
          <span>
            Timestamp:
            {` ${chart_date}`}
          </span>
        </div>
      );
    }

    return null;
  };
  const fetchHistoricData = async () => {
    if (coin && coin.id) {
      const { data } = await axios.get(
        HistoricalChart(coin.id, days, currency)
      );

      setflag(true);
      setHistoricData(data.prices);
    }
  };

  // useEffect(() => {
  //   let hoursData = timeMappers();
  //   if (firstData && firstData.length > 0) {
  //     firstData.forEach((e, i) => {
  //       setHistoricData((old) => [...old, { ...e, chart_date: hoursData[i] }]);
  //     });
  //   }
  // }, [firstData]);

  useEffect(() => {
    if (coin && coin.id) {
      fetchHistoricData();
    } else {
      getChatData('hourly');
    }
  }, [symbol]);

  useEffect(() => {
    if (coin && coin.id) {
      fetchHistoricData();
    }
  }, [days]);

  const updateChart = (value) => {
    if (value == 1) {
      getChatData('hourly');
    } else if (value == 7) {
      getChatData('weekly');
    } else if (value == 30) {
      getChatData('monthly');
    } else if (value == 90) {
      getChatData('quaterly');
    } else if (value == 365) {
      getChatData('yearly');
    }
  };

  const timeMappers = () => {
    let hoursData = [
      12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23
    ];

    let currentHour = new Date().getHours() + 1;
    let thisD = [];
    for (let i = 0; i < currentHour; i++) {
      thisD.push(hoursData[i]);
    }
    for (let i = 23; i > currentHour; i--) {
      thisD = [hoursData[i], ...thisD];
    }

    return thisD;
    //new data =[-12,-11,-10,-9,-8,-7,6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9]
  };
  function tConvert(time) {
    if (time) {
      time = parseInt(time);
      // If time format correct
      // Remove full string match value

      time =
        time < 12
          ? time + ' : 00 ' + 'AM'
          : time > 12
          ? (time % 12) + ' : 00 ' + 'PM'
          : 12 + ' : 00  '; // Set AM/PM

      //time = time % 12 || 12; // Adjust hours
    }
    return time; // return adjusted time or original string
  }

  const handleButtonClick = (button) => {
    setSelectedButton(button === selectedButton ? null : button);
  };
  return (
    <div>
      <Box
        sx={{
          backgroundColor: '#1a1b1f',
          borderRadius: '13.799px',
          width: '100%',
          textAlign: 'center',
          margin: '5px',
          mt: '10px'
        }}
      >
        {' '}
        <div style={{ flex: 1, padding: '10px 0' }}>
          {window?.screen?.availWidth > 600 ? (
            <Box sx={GraphTop}>
              <Box
                sx={{
                  color: '#76C8A8',
                  fontSize: { xs: '10px', sm: '12px', md: '16px' },
                  fontWeight: 'bold'
                }}
              >
                Price
              </Box>
              <Box
                sx={{
                  color: '#686F7E',
                  fontSize: { xs: '10px', sm: '12px', md: '16px' }
                }}
              >
                Market Cap
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                  borderRadius: ' 15px',
                  border: 'none',
                  backgroundColor: '#19191A',
                  width: { xs: '40px', sm: '40px', md: '100px' },
                  height: { xs: '21px', sm: '21px', md: 'auto' },
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Box
                  onClick={() => handleButtonClick(1)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: '30px', sm: '30px', md: '75px' },
                    height: { xs: '21px', sm: '30px' },
                    backgroundColor:
                      selectedButton === 1 ? '#76cbab' : '#19191A',
                    color: selectedButton === 1 ? '#fff' : '#000'
                  }}
                >
                  <Box
                    component="img"
                    sx={{ width: { xs: '10px', md: '25px' } }}
                    src={require('../../assets/images/lightning.png')}
                    alt="HootDex"
                  />
                </Box>
                <Box
                  onClick={() => handleButtonClick(2)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: '30px', sm: '30px', md: '75px' },
                    height: { xs: '21px', sm: '30px' },
                    backgroundColor:
                      selectedButton === 2 ? '#76cbab' : '#19191A',
                    color: selectedButton === 2 ? '#fff' : '#000'
                  }}
                >
                  <Box
                    component="img"
                    sx={{ width: { xs: '10px', md: '25px' } }}
                    src={require('../../assets/images/xor.png')}
                    alt="HootDex"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  gap: { md: '5px' }
                }}
              >
                {' '}
                {chartDays2.map((day) => (
                  <Box
                    sx={{
                      ...(day.value === days
                        ? ConstGraphActiveBtn
                        : ConstGraphBtn)
                    }}
                    key={day.value}
                    onClick={() => {
                      if (coin.id) {
                        setDays(day.value);
                        setflag(false);
                      } else {
                        setDays(day.value);
                        setflag(false);
                        updateChart(day.value);
                      }
                    }}
                  >
                    {formatDuration(day.label)}
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                ...GraphTop,
                position: 'relative',
                justifyContent: 'space-around',
                height: '90px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: '30px'
                }}
              >
                <Box
                  sx={{
                    color: '#76C8A8',
                    fontSize: {
                      xs: '10px',
                      sm: '16px',
                      md: '18px',
                      lg: '16px'
                    },
                    fontWeight: 'bold'
                  }}
                >
                  Price
                </Box>
                <Box
                  sx={{
                    color: '#686F7E',
                    fontSize: { xs: '10px', sm: '16px', md: '24px' }
                  }}
                >
                  Market Cap
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: '30px'
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    borderRadius: ' 15px',
                    border: 'none',
                    backgroundColor: '#19191A',
                    width: { xs: '60px', sm: '60px', md: '150px' },
                    height: { xs: '21px', sm: '21px', md: 'auto' },
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    onClick={() => handleButtonClick(1)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: { xs: '30px', sm: '30px', md: '75px' },
                      height: { xs: '21px', sm: '30px' },
                      backgroundColor:
                        selectedButton === 1 ? '#76cbab' : '#19191A',
                      color: selectedButton === 1 ? '#fff' : '#000'
                    }}
                  >
                    <Box
                      component="img"
                      sx={{ width: { xs: '10px', md: '25px' } }}
                      src={require('../../assets/images/lightning.png')}
                      alt="HootDex"
                    />
                  </Box>
                  <Box
                    onClick={() => handleButtonClick(2)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: { xs: '30px', sm: '30px', md: '75px' },
                      height: { xs: '21px', sm: '30px' },
                      backgroundColor:
                        selectedButton === 2 ? '#76cbab' : '#19191A',
                      color: selectedButton === 2 ? '#fff' : '#000'
                    }}
                  >
                    <Box
                      component="img"
                      sx={{ width: { xs: '10px', md: '25px' } }}
                      src={require('../../assets/images/xor.png')}
                      alt="HootDex"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  gap: { xs: '10px', md: '20px' },
                  position: 'absolute',
                  right: '10px',
                  top: '65px'
                }}
              >
                {' '}
                {chartDays2.map((day) => (
                  <Box
                    sx={{
                      ...(day.value === days
                        ? ConstGraphActiveBtn
                        : ConstGraphBtn)
                    }}
                    key={day.value}
                    onClick={() => {
                      if (coin.id) {
                        setDays(day.value);
                        setflag(false);
                      } else {
                        setDays(day.value);
                        setflag(false);
                        updateChart(day.value);
                      }
                    }}
                  >
                    {day.label}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <>
            <ResponsiveContainer
              width={'100%'}
              // height={window.screen.availHeight / 2.2}
              aspect={window?.screen?.availWidth > 600 ? 2 : 1.5}
            >
              <AreaChart
                data={historicData}
                style={{ width: '100%' }}
                // margin={{ top: 10, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="rgb(118, 200, 168)"
                      stopOpacity={1.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="rgb(118, 200, 168)"
                      stopOpacity={0}
                    />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="rgb(118, 200, 168)"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="rgb(118, 200, 168)"
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <XAxis dataKey="chart_date" />
                <YAxis
                  unit=""
                  domain={['auto', 'auto']}
                  tickCount={10}
                  interval={0}
                  tickFormatter={(value) => `${value}`}
                />

                {historicData
                  .filter(
                    (entry, index) =>
                      index % Math.floor(historicData.length / 7) === 0
                  )
                  .map((entry, index) => (
                    <ReferenceLine
                      key={`ref-line-${index}`}
                      y={entry.Price}
                      stroke="#25272C"
                      strokeWidth={1}
                      strokeDasharray="3 3"
                      alwaysShow={true}
                    />
                  ))}
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="linear"
                  dataKey="Price"
                  stroke="rgb(118, 200, 168)"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                  style={{
                    position: 'absolute',
                    zIndex: '100',
                    width: '100%',
                    fill: 'linear-gradient(180deg, #76C8A8 -21.34%, rgba(31, 32, 35, 0.00) 100%)'
                  }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </>
        </div>
      </Box>
    </div>
  );
};

export default CoinInfo;

import { Edit } from '@mui/icons-material';
import { api as axios } from '../api';
import React, { useState } from 'react';
import url from '../serverUrl';

function UpdateToken({ token, setTab }) {
  const [updatedToken, setUpdatedToken] = useState(token);
  const [logo, setLogo] = useState(token.logo);
  if (!token.name) {
    return;
  }
  const saveToken = () => {
    const bodyData = {
      id: updatedToken.id,
      name: updatedToken.name,
      description: updatedToken.description,
      link: updatedToken.link,
      type: updatedToken.type,
      logo: updatedToken.logo
    };
    // console.log(bodyData, updatedToken);

    axios
      .put(`${url}/crypto/updateToken`, bodyData)
      .then((res) => {
        alert(JSON.stringify(res.data));
        setTab('Dashboard');
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });
  };
  const saveFileDerivatives = (e) => {
    const formData = new FormData();
    const pic = e.target.files[0];
    setLogo(URL.createObjectURL(pic));
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setUpdatedToken({
          ...updatedToken,
          logo: `${url}/hootdex/images/${res.data.fileName}`
        });
      }
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setUpdatedToken({ ...updatedToken, [name]: value });
  };
  return (
    <div style={{ backgroundColor: 'whitesmoke', padding: '5rem' }}>
      <div style={{ position: 'relative' }}>
        <img src={logo} width={150} alt={token.name} />
        <input
          id="fileInput"
          className="input"
          onChange={saveFileDerivatives}
          type="file"
          placeholder="Enter"
          hidden={true}
        ></input>

        <label
          for="fileInput"
          style={{ position: 'absolute', bottom: '20px', cursor: 'pointer' }}
        >
          <Edit />
        </label>
      </div>

      <input
        className="input"
        name={'name'}
        value={updatedToken.name}
        label="Edit"
        onChange={handleChange}
      />
      <input
        className="input"
        name={'symbol'}
        value={updatedToken.symbol}
        disabled
      />
      <input
        className="input"
        name={'identitySymbol'}
        value={updatedToken.identitySymbol}
        disabled
      />
      <input
        className="input"
        name={'link'}
        value={updatedToken.link}
        onChange={handleChange}
      />
      <textarea
        className="input"
        style={{ height: '200px' }}
        name={'description'}
        value={updatedToken.description}
        onChange={handleChange}
      />
      <button
        style={{
          backgroundColor: 'darkgreen',
          width: '300px',
          padding: '1rem',
          color: 'white',
          margin: '2rem',
          cursor: 'pointer'
        }}
        onClick={() => {
          saveToken();
        }}
      >
        Save
      </button>
    </div>
  );
}

export default UpdateToken;

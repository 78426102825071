import './App.css';
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './screens/login';
import { useCallback, useEffect, useState } from 'react';
import DashboardIndex from './screens/DashboardIndex';
import { api as axios } from './api';
import url from './serverUrl';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from './store/actionTypes';
import { clearAllCookies, fetchTokenList, verifyToken } from './utils';
import Derivatives from './components/Tables/Exchange/Derivatives';
import { jwtDecode } from 'jwt-decode';
function App() {
  const { UserInfo, WalletInfo } = useSelector((state) => state);
  const [pecuCoins, setPecuCoins] = useState({});
  const dispatch = useDispatch();

  const fetchWallet = useCallback(async () => {
    if (WalletInfo && WalletInfo.userFound) {
      try {
        const response = await axios.get(
          `${url}/hootdex/getMycoins/${WalletInfo.uid}`
        );
        setPecuCoins(response.data[0]);
      } catch (error) {
        console.error('Error fetching wallet:', error);
      }
    }
  }, [WalletInfo]);

  useEffect(() => {
    if (WalletInfo && WalletInfo.userFound) {
      fetchWallet();
    }
  }, [WalletInfo, fetchWallet]);

  const PecuPriceHttpRequest = useCallback(() => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        const price = res.data[0].value;
        dispatch({ type: actionTypes.PECU_PRICE, price: price });
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const get_crypto_Data = useCallback(() => {
    axios.get(`${url}/crypto/index`).then((res) => {
      dispatch({ type: actionTypes.CRYPTO_DATA, cryptoData: res.data });
    });
  }, [dispatch]);

  useEffect(() => {
    PecuPriceHttpRequest();
    get_crypto_Data();
  }, [PecuPriceHttpRequest, get_crypto_Data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      PecuPriceHttpRequest();
      get_crypto_Data();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [PecuPriceHttpRequest, get_crypto_Data]);

  const isExhange = process.env.REACT_APP_IS_EXCHANGE === 'true';
  const isHootdex = !isExhange;

  useEffect(() => {
    if (isExhange) {
      // fetchWrapTokens(dispatch, actionTypes.FETCH_WRAP_TOKENS);
      // fetchCommodity(dispatch, actionTypes.FETCH_COMMODITY);
      // fetchSynthetics(dispatch, actionTypes.FETCH_SYNTHETICS);
      // fetchDerivatives(dispatch, actionTypes.FETCH_DERIVATIVES, 1, 160);
      // fetchProjectTokens(dispatch, actionTypes.FETCH_PROJECT_TOKENS);
      // fecthHoldingTokens(dispatch, actionTypes.FETCH_HOLDING_TOKENS);
      fetchTokenList(dispatch, actionTypes.FETCH_TOKEN_LIST);
    }
  }, [dispatch, isExhange]);
  useEffect(() => {
    const walletExist = localStorage.getItem('hootdex_secretcookie_wallet');
    const userExist = localStorage.getItem('hootdex_secretcookie');

    if (walletExist && walletExist !== null && walletExist !== 'null') {
      const token = JSON.parse(walletExist);
      if (token && token.id) {
        clearAllCookies();
      } else {
        if (verifyToken()) {
          const decoded = jwtDecode(walletExist);

          dispatch({
            type: actionTypes.WALLET_INFO,
            WalletInfo: decoded,
            auth_token: token
          });
        } else {
          alert(
            'Your authorization token has expired ,please reconnect your wallet'
          );
          localStorage.removeItem('hootdex_secretcookie_wallet');
        }
      }
    }

    if (userExist && userExist !== null && userExist !== 'null') {
      const userData = JSON.parse(userExist);
      const admin = userData.tier === 0;
      dispatch({ type: actionTypes.USER_INFO, UserInfo: userData });
      dispatch({ type: actionTypes.IS_ADMIN, isAdmin: admin });
    }
  }, []);
  return (
    <BrowserRouter>
      <Nav wallet={WalletInfo} fetchWallet={fetchWallet} />
      <Routes>
        <Route path="/:symbol" element={<Derivatives />} />
        <Route path="/" element={<Derivatives />} />
        {/* <Route path="/auth" element={<AuthReader />} /> */}
        <Route
          path="/dashboard"
          element={
            UserInfo && UserInfo.loggedIn ? (
              <DashboardIndex pecuCoins={pecuCoins} />
            ) : (
              <Login />
            )
          }
        />
      </Routes>{' '}
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import { Alert, Box, Collapse } from '@mui/material';
import { api as axios } from '../api';
import url from '../serverUrl';
import React, { useEffect, useState } from 'react';

export default function UpdatePoolHolding({ token, closeMe }) {
  const [user, setUser] = useState('');
  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    show: false
  });
  const [projectToken, setProjectToken] = useState({});
  const [projectTokens, setProjectTokens] = useState([]);
  const [inputData, setInputData] = useState({
    project_token_symbol: '',
    project_token_amount: 0,
    project_token_price: '',
    fileName: ''
  });

  const getProjectToken = async (symbol) => {
    axios
      .get(`${url}/wallet/get_all_tokens_project`)
      .then((res) => {
        setProjectTokens(res.data.tokens);
      })
      .catch((err) => console.log(err));
  };

  const saveFile = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setInputData({ ...inputData, fileName: res.data.fileName });
      } else {
        setAlert({
          msg: 'Image upload failed',
          type: 'error',
          show: true
        });
        setTimeout(() => {
          setAlert({
            msg: 'Image upload failed',
            type: 'error',
            show: false
          });
        }, 3000);
      }
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // alert(symbol);
    const bodyData = {
      id: token?.id,
      project_token_symbol: projectToken?.token_symbol,
      project_token_amount: projectToken?.amount_issued / 2,
      project_token_price: projectToken?.token_price,
      img: inputData.fileName
    };

    axios
      .post(`${url}/hootdex/update-liquidity-pool-by-id`, bodyData)
      .then((res) => {
        if (res.data.status === 'error') {
          setAlert({
            msg: res.data.msg,
            type: 'error',
            show: true
          });
          setTimeout(() => {
            setAlert({
              msg: res.data.msg,
              type: 'error',
              show: false
            });
          }, 4000);
        }
        if (res.data.status !== 'error') {
          window.scrollTo(0, 0);
          setInputData({
            project_token_symbol: '',
            project_token_amount: 0,
            project_token_price: '',
            fileName: ''
          });
          setAlert({
            msg: 'Pool Created!',
            type: 'success',
            show: true
          });
          setTimeout(() => {
            setAlert({
              msg: 'Pool Created!',
              type: 'success',
              show: false
            });
            closeMe();
          }, 3000);
        }
      })
      .catch((err) => {
        setAlert({
          msg: 'There was an error!',
          type: 'error',
          show: true
        });
        // console.log(err);
        setTimeout(() => {
          setAlert({
            msg: 'There was an error!',
            type: 'error',
            show: false
          });
        }, 3000);
      });
  };

  const handleChange = (e) => {
    let changeData = { ...inputData };
    let name = e.target.name;
    let value = e.target.value;

    changeData[name] = value;
    setInputData(changeData);
  };

  useEffect(() => {
    getProjectToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = localStorage.getItem('hootdex_secretcookie');

    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  return user && user.username ? (
    <div className="screen" style={{ padding: '1rem' }}>
      <Box sx={{ mt: 2, position: 'fixed', zIndex: 1002, top: 80 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
            <Alert
              variant="outlined"
              severity={alert.type}
              sx={{ mb: 2, backgroundColor: 'white', fontSize: '18px' }}
            >
              {alert.msg}
            </Alert>
          </Collapse>
        </div>
      </Box>
      <form
        id="myForm"
        className="form"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h5>Update {token?.project_name} </h5>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '93%',
            width: '93%',
            padding: '0px'
          }}
        >
          <span style={{ flex: '1' }}>
            {' '}
            <label className="label">Project Token to be staked</label>
            <select
              style={{
                padding: '0.85rem',
                width: '100%',
                marginTop: '1rem',
                color: 'orange',
                fontWeight: 'bold',
                fontSize: '1rem'
              }}
              name="project_token1"
              onChange={(e) => {
                setProjectToken(
                  ...projectTokens.filter(
                    (project) => project.token_symbol === e.target.value
                  )
                );
              }}
            >
              <option>select</option>
              {projectTokens.map((e, i) => (
                <option key={i} value={e.token_symbol}>
                  {e.token_symbol}
                </option>
              ))}
            </select>
          </span>
          {/* <span style={{ flex: '0.5' }}>
            <label className="label">Amount(Token)</label>
            <input
              type="number"
              className="input"
              name="pecuCoin"
              min={1}
              onChange={handleChange}
            />
          </span> */}
        </div>
        <label className="label">Price ({projectToken?.token_symbol})</label>
        <input
          className="input"
          type="text"
          placeholder="price"
          value={projectToken?.token_price}
          disabled
        ></input>
        <label className="label">Upload project token logo</label>
        <input
          className="input"
          onChange={saveFile}
          type="file"
          placeholder="Enter"
        ></input>
        <span>
          {' '}
          <button type="submit" className="submit-btn button">
            Submit Request
          </button>
          <button
            onClick={() => closeMe()}
            className="submit-btn button"
            style={{ backgroundColor: 'red', color: 'white' }}
          >
            Cancel
          </button>
        </span>
      </form>
    </div>
  ) : (
    <div className="screen" style={{ padding: '1rem' }}>
      <h1 style={{ color: '#fff' }}>Loading...</h1>
    </div>
  );
}

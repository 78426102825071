const symbols = {
  'ETH/USD': 'hETH',
  'BNB/USD': 'hBNB',
  'XRP/USD': 'hXRP',
  'ADA/USD': 'hADA',
  'DOGE/USD': 'hDOGE',
  'MATIC/USD': 'hMATIC',
  'SOL/USD': 'hSOL',
  'PDOTN/USD': 'hDOT',
  'AVAX/USD': 'hAVAX',
  'LTC/USD': 'hLTC',
  'UNIS/USD': 'hUNI',
  'LINK/USD': 'hLINK',
  'BCH/USD': 'hBCH',
  'XMR/USD': 'hXMR',
  'ETC/USD': 'hETC',
  'BTC/USD': 'hBTC',
  'BTC/BNB': 'hBTCBN',
  'BNB/BTC': 'hBNBBT',
  'BNB/ETH': 'hBNBET',
  'ETH/BNB': 'hETHBN',
  'BNB/EUR': 'hBNBEU',
  'BNB/GBP': 'hBNBGB',
  'BNB/XAU': 'hBNBAU',
  'BNB/XPT': 'hBNBPT',
  'BNB/XPD': 'hBNBPD',
  'PECU/BTC': 'hPECUBT',
  'PECU/ETH': 'hPECUET',
  'PECU/GBP': 'hPECUGB',
  'PECU/EUR': 'hPECUEU',
  'PECU/XAU': 'hPECUAU',
  'PECU/XPT': 'hPECUPT',
  'PECU/XPD': 'hPECUPD',
  'BTC/EUR': 'hBTCEU',
  'BTC/GBP': 'hBTCGB',
  'BTC/XAU': 'hBTCAU',
  'BTC/XPT': 'hBTCPT',
  'BTC/XPD': 'hBTCPD',
  'BTC/WTI': 'hBTCWT',
  'BTC/XBR': 'hBTCBR',
  'BTC/ETH': 'hBTCET',
  'LTC/EUR': 'hLTCEU',
  'LTC/GBP': 'hLTCGB',
  'LTC/XAU': 'hLTCAU',
  'LTC/XPT': 'hLTCPT',
  'LTC/XPD': 'hLTCPD',
  'LTC/WTI': 'hLTCWT',
  'LTC/XBR': 'hLTCBR',
  'LTC/BTC': 'hLTCBT',
  'LTC/ETH': 'hLTCET',
  'ETH/BTC': 'hETHBT',
  'ETH/XBR': 'hETHBR',
  'ETH/WTI': 'hETHWT',
  'ETH/XPD': 'hETHPD',
  'ETH/XPT': 'hETHPT',
  'ETH/XAU': 'hETHAU',
  'ETH/GBP': 'hETHGB',
  'ETH/EUR': 'hETHEU',
  'BTC/JPY': 'hBJP',
  'PECU/JPY': 'hPJP',
  'ETH/JPY': 'hEJP',
  'BTC/CHF': 'hBTCH',
  'ETH/CHF': 'hECH',
  'PECU/CHF': 'hPCH',
  'BTC/HKD': 'hBHK',
  'PECU/HKD': 'hPHK',
  'ETH/HKD': 'hEHK',
  'LTC/HKD': 'hLHK',
  'LTC/JPY': 'hLJP',
  'LTC/CHF': 'hLCH',
  'BTC/CNY': 'hBCN',
  'ETH/CNY': 'hECN',
  'LTC/CNY': 'hLCN',
  'PECU/CNY': 'hPCN',
  'BTC/NZD': 'hBNZ',
  'PECU/NZD': 'hPNZ',
  'ETH/NZD': 'hENZ',
  'LTC/NZD': 'hLNZ',
  'BTC/AED': 'hBAE',
  'PECU/AED': 'hPAE',
  'ETH/AED': 'hEAE',
  'LTC/AED': 'hLAE',
  'BTC/JOD': 'hBJO',
  'PECU/JOD': 'hPJO',
  'ETH/JOD': 'hEJO',
  'LTC/JOD': 'hLJO',
  'BTC/RUB': 'hBRU',
  'PECU/RUB': 'hPRU',
  'ETH/RUB': 'hERU',
  'LTC/RUB': 'hLRU',
  'BTC/INR': 'hBIN',
  'PECU/INR': 'hPIN',
  'ETH/INR': 'hEIN',
  'LTC/INR': 'hLIN',
  'BTC/SGD': 'hBSG',
  'PECU/SGD': 'hPSG',
  'ETH/SGD': 'hESG',
  'LTC/SGD': 'hLSG',
  'BTC/MXN': 'hBMX',
  'PECU/MXN': 'hPMX',
  'ETH/MXN': 'hEMX',
  'LTC/MXN': 'hLMX',
  'BTC/NOK': 'hBNO',
  'PECU/NOK': 'hPNO',
  'ETH/NOK': 'hENO',
  'LTC/NOK': 'hLNO',
  'BTC/SEK': 'hBSE',
  'PECU/SEK': 'hPSE',
  'ETH/SEK': 'hESE',
  'LTC/SEK': 'hLSE',
  'BTC/TWD': 'hBTW',
  'PECU/TWD': 'hPTW',
  'ETH/TWD': 'hETW',
  'LTC/TWD': 'hLTW',
  'BTC/HUF': 'hBHU',
  'PECU/HUF': 'hPHU',
  'ETH/HUF': 'hEHU',
  'LTC/HUF': 'hLHU',
  'BTC/TRY': 'hBTR',
  'PECU/TRY': 'hPTR',
  'ETH/TRY': 'hETR',
  'LTC/TRY': 'hLTR',
  'BTC/SAR': 'hBSA',
  'PECU/SAR': 'hPSA',
  'ETH/SAR': 'hESA',
  'LTC/SAR': 'hLSA',
  'BTC/EGP': 'hBEG',
  'PECU/EGP': 'hPEG',
  'ETH/EGP': 'hEEG',
  'LTC/EGP': 'hLEG',
  'BTC/GHS': 'hBGH',
  'PECU/GHS': 'hPGH',
  'ETH/GHS': 'hEGH',
  'LTC/GHS': 'hLGH',
  'BTC/UAH': 'hBUA',
  'PECU/UAH': 'hPUA',
  'ETH/UAH': 'hEUA',
  'LTC/UAH': 'hLUA',
  'BTC/PHP': 'hBPH',
  'PECU/PHP': 'hPPH',
  'ETH/PHP': 'hEPH',
  'LTC/PHP': 'hLPH',
  'BTC/MYR': 'hBMY',
  'PECU/MYR': 'hPMY',
  'ETH/MYR': 'hEMY',
  'LTC/MYR': 'hLMY',
  'BTC/KRW': 'hBKR',
  'PECU/KRW': 'hPKR',
  'ETH/KRW': 'hEKR',
  'LTC/KRW': 'hLKR',
  'BTC/IDR': 'hBID',
  'PECU/IDR': 'hPID',
  'ETH/IDR': 'hEID',
  'LTC/IDR': 'hLID',
  'BTC/ILS': 'hBIL',
  'PECU/ILS': 'hPIL',
  'ETH/ILS': 'hEIL',
  'LTC/ILS': 'hLIL',
  'BTC/PYG': 'hBPY',
  'PECU/PYG': 'hPPY',
  'ETH/PYG': 'hEPY',
  'LTC/PYD': 'hLPY',
  'BTC/THB': 'hBTH',
  'PECU/THB': 'hPTH',
  'ETH/THB': 'hETTH',
  'LTC/THB': 'hLTH',
  'BTC/PLN': 'hBPL',
  'PECU/PLN': 'hPPL',
  'ETH/PLN': 'hEPL',
  'LTC/PLN': 'hLPL'
};

export default symbols;

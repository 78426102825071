import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TopDivStyle } from './DerivativesStyles';
import Table from './Table';
import CoinInfo from '../../coinInfo/coinInfo';
import './Derivatives.css';
import LeftPanel from './LeftPanel';
import {
  Avatar,
  Button,
  CircularProgress,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import url from '../../../serverUrl';
import { api as axios } from '../../../api';
import { useParams } from 'react-router-dom';
import MyTradeTable from './myTrade';
import { Backdrop } from '@mui/material';
import { logoWrapper } from '../../../utils';
import symbols from '../../../symbolsMapper';

const OrderTypeEnum = {
  SELL_MARKET_ORDER: 'SELL MARKET ORDER',
  BUY_MARKET_ORDER: 'BUY MARKET ORDER',
  BUY_LIMIT_ORDER: 'BUY LIMIT ORDER',
  SELL_LIMIT_ORDER: 'SELL LIMIT ORDER'
};
const orderTypeList = [
  OrderTypeEnum.BUY_LIMIT_ORDER,
  OrderTypeEnum.BUY_MARKET_ORDER,
  OrderTypeEnum.SELL_LIMIT_ORDER,
  OrderTypeEnum.SELL_MARKET_ORDER
];
const SwapSystem = ({
  token,
  PecuPrice,
  projectTokens,
  holdingTokens,
  bid,
  ask,
  auth_token,
  fetchWalletAsset
}) => {
  const [swapSymbol, setSwapSymbol] = useState('');
  const [swapAmount, setSwapAmount] = useState(1);
  const [limitPrice, setLimitPrice] = useState(1);
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bidPrice, setBidPrice] = useState(0);
  const [askPrice, setAskPrice] = useState(0);
  const [scheduleTime, setScheduleTime] = useState('');
  const [partialOrder, setPartialOrder] = useState(false);
  const [isActiveBtn, setActive] = useState('buy');
  const [swapType, setSwapType] = useState(OrderTypeEnum.BUY_MARKET_ORDER);
  const headers = {
    Authorization: `Bearer ${auth_token}`
  };
  useEffect(() => {
    if (isActiveBtn === 'buy') {
      setSwapType(OrderTypeEnum.BUY_MARKET_ORDER);
    } else {
      setSwapType(OrderTypeEnum.SELL_MARKET_ORDER);
    }
  }, [isActiveBtn]);
  useEffect(() => {
    if (bid.length > 0 && bid[0].price) {
      setBidPrice(bid[0].price);
    }
  }, [bid]);
  useEffect(() => {
    if (ask.length > 0 && ask[0].price) {
      setAskPrice(ask[0]?.price);
    }
  }, [ask]);
  const placeOrderConfirmed = async () => {
    const auth_string = localStorage.getItem('hootdex_secretcookie_wallet');
    if (!auth_string) {
      return alert('wallet not connected');
    }

    if (auth_token) {
      const symbol = token?.symbol || token?.token_symbol;
      const quantity = swapAmount;

      if (swapType === OrderTypeEnum.BUY_MARKET_ORDER) {
        const payload = {
          symbol,
          askPrice: askPrice,
          quantity
        };
        const response = await axios.post(
          `${url}/crypto/marketOrder`,
          payload,
          { headers }
        );
        setConfirmationBox(false);
        setSwapAmount(1);
        fetchWalletAsset();
        alert(JSON.stringify(response.data));
        return;
      }
      if (swapType === OrderTypeEnum.SELL_MARKET_ORDER) {
        //place bid

        const payload = {
          symbol,
          askPrice: askPrice,
          quantity
        };
        try {
          const response = await axios.post(
            `${url}/crypto/sellmarketOrder`,
            payload,
            { headers }
          );
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          fetchWalletAsset();
          alert(JSON.stringify(response.data));
        } catch (error) {
          // console.log(error);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(error));
        }
      } else if (swapType.includes('SELL')) {
        const payload = {
          symbol,
          bidPrice: ['SELL LIMIT ORDER'].includes(swapType)
            ? limitPrice
            : bidPrice,
          quantity,

          swapType,
          scheduleTime
        };
        try {
          const response = await axios.post(`${url}/crypto/placeAsk`, payload, {
            headers
          });
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          fetchWalletAsset();
          alert(JSON.stringify(response.data));
        } catch (error) {
          // console.log(error);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(error));
        }
      } else if (swapType.includes('BUY')) {
        //place ask
        const payload = {
          symbol,
          askPrice: ['BUY LIMIT ORDER'].includes(swapType)
            ? limitPrice
            : askPrice,
          quantity,
          swapType,
          scheduleTime,
          partialOrder
        };
        try {
          setLoading(true);
          const response = await axios.post(`${url}/crypto/placeBid`, payload, {
            headers
          });
          setLoading(false);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          fetchWalletAsset();
          alert(JSON.stringify(response.data));
        } catch (error) {
          setLoading(false);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(error));
        }
      }
    } else {
      alert('Please Login');
    }
  };
  const placeOrder = () => {
    setConfirmationBox(true);
  };

  const swapTokens = async (token, tokenSwapTo, amount) => {
    let auth_token = localStorage.getItem('hootdex_secretcookie_wallet');
    const headers = {
      Authorization: `Bearer ${auth_token}`
    };
    if (auth_token) {
      const tokenSwapFrom = token.identitySymbol || token?.token_symbol;
      try {
        const response = await axios.post(
          `${url}/wallet/swap`,
          {
            tokenSwapFrom,
            tokenSwapTo,
            amount
          },
          { headers }
        );
        fetchWalletAsset();
        alert(JSON.stringify(response.data));
      } catch (error) {
        // console.log(error);
        alert(JSON.stringify(error));
      }
    } else {
      alert('Please Login');
    }
  };

  if (loading) {
    return (
      <Box
        className="border"
        sx={{
          backdropFilter: 'blur(5px)',
          p: 3,

          height: 'max-content'
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              <span>
                <p
                  className="fontS10"
                  style={{ color: 'white', fontSize: '20px' }}
                >
                  Transaction in progress
                </p>
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
              >
                Please Wait
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', minHeight: '350px' }}>
              <CircularProgress />
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (confirmationBox) {
    return (
      <Box
        className="border"
        sx={{
          backdropFilter: 'blur(5px)',
          p: 3,

          height: 'max-content'
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              <span>
                <p
                  className="fontS10"
                  style={{ color: 'white', fontSize: '20px' }}
                >
                  Confirm Swap Order
                </p>
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
                onClick={placeOrderConfirmed}
              >
                Confirm
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: 'red',
                  width: '100%'
                }}
                onClick={() => {
                  setConfirmationBox(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.SELL_MARKET_ORDER) {
    return (
      <Box
        // className="border"
        sx={{
          backgroundColor: '#1C1D20',
          backdropFilter: 'blur(5px)',
          borderRadius: '15px',
          marginTop: '10px',
          marginLeft: '10px',
          width: 'auto !important',
          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {token?.identitySymbol ?? token?.token_symbol}
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={logoWrapper(token?.logo ?? token?.image)}
              />{' '}
              <Box
                component="p"
                className="fontS10"
                style={{
                  color: 'grey',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                1 {token?.identitySymbol ?? token?.token_symbol}
              </Box>
            </Box>
            <Box
              component="span"
              className="fontS10"
              sx={{
                color: 'grey',
                fontSize: { xs: '12px', sm: '14px', lg: '16px' }
              }}
            >
              {'≈'}
            </Box>
            <span>
              <Box
                component="p"
                sx={{
                  color: '#FFF',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                {bidPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </Box>
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
              />{' '}
              <p
                className="fontS10"
                style={{ color: '#686F7E', fontSize: '16px' }}
              >
                1 {'PECU'}
              </p>
            </Box>
            <p
              className="fontS10"
              style={{ color: '#686F7E', fontSize: '16px' }}
            >
              {'≈'}
            </p>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <p
                className="fontS15"
                style={{ color: '##FFFFFF', fontSize: '16px' }}
              >
                {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </p>
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: '80%',
            margin: '20px',
            height: '45px',
            borderRadius: '42.695px',
            border: '1.265px solid #25272C',
            backdropFilter: 'blur(26.557376861572266px)'
          }}
        >
          <Button
            onClick={() => setActive('buy')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'buy' ? 'activeBtn' : ''}
          >
            Buy
          </Button>
          <Button
            onClick={() => setActive('sell')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'sell' ? 'activeBtn' : ''}
          >
            Sell
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <Box
            component="select"
            style={{
              width: '100%',
              padding: '14px',
              backgroundColor: '#19191A',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem',
              borderRadius: '15px',
              cursor: 'pointer'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map(
              (e, i) =>
                e.includes(isActiveBtn.toUpperCase()) && (
                  <option style={{ cursor: 'pointer' }} value={e} key={i}>
                    {e}
                  </option>
                )
            )}
          </Box>
        </Box>

        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Box
              style={{
                textAlign: 'left',
                width: '100% !important',
                minWidth: '300px'
              }}
            >
              {' '}
              <h3 sx={{ margin: '0', padding: '0' }}>
                Enter Amount ({token?.identitySymbol || token?.token_symbol})
              </h3>
              <Box
                sx={{
                  gap: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#19191A',
                  borderRadius: '25px',
                  padding: '12px'
                }}
              >
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '28px',
                    height: '28px',
                    color: 'rgb(86, 90, 105)',
                    borderRight: '1px solid  #25272C'
                  }}
                  src={logoWrapper(token?.logo || token?.image)}
                />{' '}
                <input
                  autoFocus
                  className="right-input"
                  type={'number'}
                  style={{
                    outline: 'none',
                    backgroundColor: '#19191A',
                    width: '100%',
                    padding: '5px',
                    fontSize: '1rem',
                    color: 'white',
                    fontWeight: 'bold',
                    border: 'none'
                  }}
                  value={swapAmount}
                  onChange={(e) => setSwapAmount(e.target.value)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              style={{
                textAlign: 'left',
                width: '100% !important',
                minWidth: '300px'
              }}
            >
              <h3>Estimated Total</h3>

              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '25px',
                      height: '25px',
                      color: 'rgb(86, 90, 105)',
                      marginRight: '8px'
                    }}
                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                  />
                  <h3>Total (PECU)</h3>
                </Box>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem',
                      border: 'none',
                      color: 'white',
                      padding: '15px',
                      borderRadius: '15px'
                    }}
                    disabled
                    value={(swapAmount * bidPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'white',
                    fontSize: '13px'
                  }}
                >
                  <h3>Total (USD)</h3>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem',
                      border: 'none',
                      color: 'white',
                      padding: '15px',
                      borderRadius: '15px'
                    }}
                    disabled
                    value={(swapAmount * bidPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  borderRadius: '25px',
                  padding: '10px',
                  backgroundColor: '#76C8A8',
                  width: '100%',
                  color: 'black'
                }}
              >
                Place Sell Order
                <img
                  style={{ position: 'absolute', right: '5px' }}
                  src={require('../../../assets/images/DownloadIcon.png')}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.BUY_MARKET_ORDER) {
    return (
      <Box
        sx={{
          backgroundColor: '#1C1D20',
          backdropFilter: 'blur(5px)',
          borderRadius: '15px',
          marginTop: '10px',
          marginLeft: '10px',

          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {token?.identitySymbol ?? token?.token_symbol}
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={logoWrapper(token?.logo ?? token?.image)}
              />{' '}
              <Box
                component="p"
                className="fontS10"
                style={{
                  color: 'grey',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                1 {token?.identitySymbol ?? token?.token_symbol}
              </Box>
            </Box>
            <Box
              component="span"
              className="fontS10"
              sx={{
                color: 'grey',
                fontSize: { xs: '12px', sm: '14px', lg: '16px' }
              }}
            >
              {'≈'}
            </Box>
            <span>
              <Box
                component="p"
                sx={{
                  color: '#FFF',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                {bidPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </Box>
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
              />{' '}
              <p
                className="fontS10"
                style={{ color: '#686F7E', fontSize: '16px' }}
              >
                1 {'PECU'}
              </p>
            </Box>
            <p
              className="fontS10"
              style={{ color: '#686F7E', fontSize: '16px' }}
            >
              {'≈'}
            </p>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <p
                className="fontS15"
                style={{ color: '##FFFFFF', fontSize: '16px' }}
              >
                {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </p>
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: '80%',
            margin: '20px',
            height: '45px',
            borderRadius: '42.695px',
            border: '1.265px solid #25272C',
            backdropFilter: 'blur(26.557376861572266px)'
          }}
        >
          <Button
            onClick={() => setActive('buy')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'buy' ? 'activeBtn' : ''}
          >
            Buy
          </Button>
          <Button
            onClick={() => setActive('sell')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'sell' ? 'activeBtn' : ''}
          >
            Sell
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <Box
            component="select"
            style={{
              width: '100%',
              padding: '14px',
              backgroundColor: '#19191A',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem',
              borderRadius: '15px',
              cursor: 'pointer'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map(
              (e, i) =>
                e.includes(isActiveBtn.toUpperCase()) && (
                  <option style={{ cursor: 'pointer' }} value={e} key={i}>
                    {e}
                  </option>
                )
            )}
          </Box>
        </Box>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'left',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <h3>
                  Enter Amount ({token?.identitySymbol || token?.token_symbol})
                </h3>
                <Box
                  sx={{
                    gap: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#19191A',
                    borderRadius: '25px',
                    padding: '8px'
                  }}
                >
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '28px',
                      height: '28px',
                      color: 'rgb(86, 90, 105)',
                      margin: '8px'
                    }}
                    src={logoWrapper(token?.logo || token?.image)}
                  />{' '}
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '5px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />
                </Box>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'left',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h5>Estimated Total</h5>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '25px',
                      height: '25px',
                      color: 'rgb(86, 90, 105)',
                      marginRight: '8px'
                    }}
                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                  />
                  <h3>Total (PECU)</h3>
                </Box>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '5px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={(swapAmount * askPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h3>Total (USD)</h3>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '5px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={(swapAmount * askPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  borderRadius: '25px',
                  padding: '10px',
                  backgroundColor: '#76C8A8',
                  width: '100%',
                  color: 'black'
                }}
              >
                Place Buy Order
                <img
                  style={{ position: 'absolute', right: '5px' }}
                  src={require('../../../assets/images/DownloadIcon.png')}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.BUY_LIMIT_ORDER) {
    return (
      <Box
        sx={{
          backgroundColor: '#1C1D20',
          backdropFilter: 'blur(5px)',
          borderRadius: '15px',
          marginTop: '10px',
          marginLeft: '10px',

          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {token?.identitySymbol ?? token?.token_symbol}
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={logoWrapper(token?.logo ?? token?.image)}
              />{' '}
              <Box
                component="p"
                className="fontS10"
                style={{
                  color: 'grey',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                1 {token?.identitySymbol ?? token?.token_symbol}
              </Box>
            </Box>
            <Box
              component="span"
              className="fontS10"
              sx={{
                color: 'grey',
                fontSize: { xs: '12px', sm: '14px', lg: '16px' }
              }}
            >
              {'≈'}
            </Box>
            <span>
              <Box
                component="p"
                sx={{
                  color: '#FFF',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                {bidPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </Box>
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
              />{' '}
              <p
                className="fontS10"
                style={{ color: '#686F7E', fontSize: '16px' }}
              >
                1 {'PECU'}
              </p>
            </Box>
            <p
              className="fontS10"
              style={{ color: '#686F7E', fontSize: '16px' }}
            >
              {'≈'}
            </p>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <p
                className="fontS15"
                style={{ color: '##FFFFFF', fontSize: '16px' }}
              >
                {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </p>
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: '80%',
            margin: '20px',
            height: '45px',
            borderRadius: '42.695px',
            border: '1.265px solid #25272C',
            backdropFilter: 'blur(26.557376861572266px)'
          }}
        >
          <Button
            onClick={() => setActive('buy')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'buy' ? 'activeBtn' : ''}
          >
            Buy
          </Button>
          <Button
            onClick={() => setActive('sell')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'sell' ? 'activeBtn' : ''}
          >
            Sell
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <Box
            component="select"
            style={{
              width: '100%',
              padding: '14px',
              backgroundColor: '#19191A',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem',
              borderRadius: '15px',
              cursor: 'pointer',
              textTransform: 'none !important'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map(
              (e, i) =>
                e.includes(isActiveBtn.toUpperCase()) && (
                  <option style={{ cursor: 'pointer' }} value={e} key={i}>
                    {e}
                  </option>
                )
            )}
          </Box>
        </Box>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'left',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <h3>Limit Price({'USD'})</h3>
                <Box
                  sx={{
                    gap: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#19191A',
                    borderRadius: '25px',
                    padding: '12px'
                  }}
                >
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '28px',
                      height: '28px',
                      color: 'rgb(86, 90, 105)',
                      margin: '8px'
                    }}
                    src={logoWrapper(token?.logo || token?.image)}
                  />{' '}
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '5px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none',
                      borderRadius: '15px'
                    }}
                    value={limitPrice}
                    onChange={(e) => setLimitPrice(e.target.value)}
                  />
                </Box>

                {/* <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>Price({'USD'})</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={token?.currentPrice
                      ?.toFixed(5)
                      ?.toLocaleString('en-US')}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div> */}

                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h3>
                    Time of Limit Order(
                    {token?.identitySymbol || token?.token_symbol})
                  </h3>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'datetime-local'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: ' 10px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none',
                      borderRadius: '15px'
                    }}
                    value={scheduleTime}
                    onChange={(e) => setScheduleTime(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Enter Amount ({token?.identitySymbol || token?.token_symbol}
                    )
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '10px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none',
                      borderRadius: '15px'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'left',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
            >
              {' '}
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h3>Estimated Total</h3>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '25px',
                      height: '25px',
                      color: 'rgb(86, 90, 105)',
                      marginRight: '8px'
                    }}
                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                  />

                  <h3 style={{ color: '#686F7E' }}>Total (PECU)</h3>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </Box>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '5px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={(swapAmount * limitPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h3>Total (USD)</h3>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '5px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={(swapAmount * limitPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={10}>
            <div
              style={{
                width: '100%',
                margin: '0.5rem 0 0.5rem 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '1rem'
              }}
            >
              <label
                style={{
                  fontSize: '16px',
                  color: '#686F7E',
                  fontWeight: 'bold',
                  margin: '1rem'
                }}
              >
                Order Type
              </label>{' '}
              {/*
              <Switch
                title="Partial Order"
                value={partialOrder}
                onChange={() => {
                  setPartialOrder(!partialOrder);
                }}
              /> */}
              <select
                style={{
                  width: '100%',
                  padding: '8px',
                  backgroundColor: '#19191A',
                  outline: 'none',
                  border: 'none',
                  borderRadius: '10px',
                  padding: '10px',
                  color: 'white'
                }}
                onChange={(e) => {
                  setPartialOrder(e.target.value);
                }}
              >
                <option value={'Partial'}>Partial</option>
                <option value={'All Or None'}>All Or None</option>
              </select>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  borderRadius: '25px',
                  padding: '10px',
                  backgroundColor: '#76C8A8',
                  width: '100%',
                  color: 'black'
                }}
              >
                Place Buy Order
                <img
                  style={{ position: 'absolute', right: '5px' }}
                  src={require('../../../assets/images/DownloadIcon.png')}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.SELL_LIMIT_ORDER) {
    return (
      <Box
        sx={{
          backgroundColor: '#1C1D20',
          backdropFilter: 'blur(5px)',
          borderRadius: '15px',
          marginTop: '10px',
          marginLeft: '10px',

          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {token?.identitySymbol ?? token?.token_symbol}
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={logoWrapper(token?.logo ?? token?.image)}
              />{' '}
              <Box
                component="p"
                className="fontS10"
                style={{
                  color: 'grey',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                1 {token?.identitySymbol ?? token?.token_symbol}
              </Box>
            </Box>
            <Box
              component="span"
              className="fontS10"
              sx={{
                color: 'grey',
                fontSize: { xs: '12px', sm: '14px', lg: '16px' }
              }}
            >
              {'≈'}
            </Box>
            <span>
              <Box
                component="p"
                sx={{
                  color: '#FFF',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                {bidPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </Box>
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
              />{' '}
              <p
                className="fontS10"
                style={{ color: '#686F7E', fontSize: '16px' }}
              >
                1 {'PECU'}
              </p>
            </Box>
            <p
              className="fontS10"
              style={{ color: '#686F7E', fontSize: '16px' }}
            >
              {'≈'}
            </p>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <p
                className="fontS15"
                style={{ color: '##FFFFFF', fontSize: '16px' }}
              >
                {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </p>
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: '80%',
            margin: '20px',
            height: '45px',
            borderRadius: '42.695px',
            border: '1.265px solid #25272C',
            backdropFilter: 'blur(26.557376861572266px)'
          }}
        >
          <Button
            onClick={() => setActive('buy')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'buy' ? 'activeBtn' : ''}
          >
            Buy
          </Button>
          <Button
            onClick={() => setActive('sell')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'sell' ? 'activeBtn' : ''}
          >
            Sell
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <Box
            component="select"
            style={{
              width: '100%',
              padding: '14px',
              backgroundColor: '#19191A',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem',
              borderRadius: '15px',
              cursor: 'pointer'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map(
              (e, i) =>
                e.includes(isActiveBtn.toUpperCase()) && (
                  <option style={{ cursor: 'pointer' }} value={e} key={i}>
                    {e}
                  </option>
                )
            )}
          </Box>
        </Box>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'left',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '28px',
                      height: '28px',
                      color: 'rgb(86, 90, 105)',
                      margin: '8px'
                    }}
                    src={logoWrapper(token?.logo || token?.image)}
                  />{' '}
                  <h3>Limit Price({'USD'})</h3>
                </Box>
                {/* <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>Price({'USD'})</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={token?.currentPrice
                      ?.toFixed(5)
                      ?.toLocaleString('en-US')}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div> */}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    value={limitPrice}
                    onChange={(e) => setLimitPrice(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h3>
                    Time of Limit Order(
                    {token?.identitySymbol || token?.token_symbol})
                  </h3>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'datetime-local'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    value={scheduleTime}
                    onChange={(e) => setScheduleTime(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h3>
                    Enter Amount ({token?.identitySymbol || token?.token_symbol}
                    )
                  </h3>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'lefft',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h3>Estimated Total</h3>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '25px',
                      height: '25px',
                      color: 'rgb(86, 90, 105)',
                      marginRight: '8px'
                    }}
                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                  />

                  <h3 sx={{ coloe: '#686F7E' }}>Total (PECU)</h3>
                </Box>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={(swapAmount * limitPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (USD)</h5>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={(swapAmount * limitPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={10}>
            <div
              style={{
                width: '100%',
                margin: '0.5rem 0 0.5rem 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '1rem'
              }}
            >
              <label
                style={{ color: '#686F7E', fontWeight: 'bold', margin: '1rem' }}
              >
                Order Type
              </label>{' '}
              {/*
              <Switch
                title="Partial Order"
                value={partialOrder}
                onChange={() => {
                  setPartialOrder(!partialOrder);
                }}
              /> */}
              <select
                style={{
                  width: '100%',
                  padding: '8px',
                  backgroundColor: '#19191A',
                  border: 'none',
                  color: 'white',
                  padding: '10px'
                }}
                onChange={(e) => {
                  setPartialOrder(e.target.value);
                }}
              >
                <option value={'Partial fill'}>PARTIAL FILL</option>
                <option value={'All Or None'}>ALL OR NONE</option>
              </select>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  borderRadius: '25px',
                  padding: '12px',
                  backgroundColor: '#76C8A8',
                  width: '100%',
                  color: 'black'
                }}
              >
                Place Sell Order
                <img
                  style={{ position: 'absolute', right: '5px' }}
                  src={require('../../../assets/images/DownloadIcon.png')}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.TOKEN_SWAP) {
    return (
      <Box
        sx={{
          backgroundColor: '#1C1D20',
          backdropFilter: 'blur(5px)',
          borderRadius: '15px',
          marginTop: '10px',
          marginLeft: '10px',

          p: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {token?.identitySymbol ?? token?.token_symbol}
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={logoWrapper(token?.logo ?? token?.image)}
              />{' '}
              <Box
                component="p"
                className="fontS10"
                style={{
                  color: 'grey',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                1 {token?.identitySymbol ?? token?.token_symbol}
              </Box>
            </Box>
            <Box
              component="span"
              className="fontS10"
              sx={{
                color: 'grey',
                fontSize: { xs: '12px', sm: '14px', lg: '16px' }
              }}
            >
              {'≈'}
            </Box>
            <span>
              <Box
                component="p"
                sx={{
                  color: '#FFF',
                  fontSize: { xs: '12px', sm: '14px', lg: '16px' }
                }}
              >
                {bidPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </Box>
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'end !important',
              borderBottom: '1px solid #25272C',
              gap: '20px',
              padding: '15px 0'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                className="rounded"
                alt="token logo"
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'rgb(86, 90, 105)',
                  marginRight: '8px'
                }}
                src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
              />{' '}
              <p
                className="fontS10"
                style={{ color: '#686F7E', fontSize: '16px' }}
              >
                1 {'PECU'}
              </p>
            </Box>
            <p
              className="fontS10"
              style={{ color: '#686F7E', fontSize: '16px' }}
            >
              {'≈'}
            </p>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <p
                className="fontS15"
                style={{ color: '##FFFFFF', fontSize: '16px' }}
              >
                {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
              </p>
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: '80%',
            margin: '20px',
            height: '45px',
            borderRadius: '42.695px',
            border: '1.265px solid #25272C',
            backdropFilter: 'blur(26.557376861572266px)'
          }}
        >
          <Button
            onClick={() => setActive('buy')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'buy' ? 'activeBtn' : ''}
          >
            Buy
          </Button>
          <Button
            onClick={() => setActive('sell')}
            sx={{
              fontWeight: 'bold',
              color: 'white',
              width: '50%',
              height: '100%',
              borderRadius: '42.695px'
            }}
            className={isActiveBtn === 'sell' ? 'activeBtn' : ''}
          >
            Sell
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <Box
            component="select"
            style={{
              width: '100%',
              padding: '14px',
              backgroundColor: '#19191A',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem',
              borderRadius: '15px',
              cursor: 'pointer'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map(
              (e, i) =>
                e.includes(isActiveBtn.toUpperCase()) && (
                  <option style={{ cursor: 'pointer' }} value={e} key={i}>
                    {e}
                  </option>
                )
            )}
          </Box>
        </Box>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'left',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <h3>
                  Enter Amount ({token?.identitySymbol || token?.token_symbol})
                </h3>
                <Box
                  sx={{
                    gap: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#19191A',
                    borderRadius: '25px',
                    padding: '8px'
                  }}
                >
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '28px',
                      height: '28px',
                      color: 'rgb(86, 90, 105)',
                      margin: '8px'
                    }}
                    src={logoWrapper(token?.logo || token?.image)}
                  />{' '}
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />
                </Box>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'left',
                backgroundColor: '#1C1D20',
                minWidth: '300px'
              }}
            >
              {' '}
              {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <h3>Estimated Total</h3>{' '}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '25px',
                      height: '25px',
                      color: 'rgb(86, 90, 105)',
                      marginRight: '8px'
                    }}
                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                  />
                  <h3 sx={{ color: '#686F7E' }}>Total (PECU)</h3>
                </Box>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={
                      token?.currentPrice
                        ? (swapAmount * token?.currentPrice) / PecuPrice
                        : (swapAmount * token?.token_price) / PecuPrice
                    }
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h3>Total (USD)</h3>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: '#19191A',
                      width: '100%',
                      padding: '8px',
                      fontSize: '1rem',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none'
                    }}
                    disabled
                    value={(token?.currentPrice
                      ? swapAmount * token?.currentPrice
                      : swapAmount * token?.token_price
                    )?.toFixed(5)}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',

                    fontSize: '13px',
                    color: 'white'
                  }}
                >
                  <h5>Swap To</h5>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                  }}
                >
                  {' '}
                  <select
                    style={{
                      width: '100%',
                      padding: '12px',
                      backgroundColor: '#19191A',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none',
                      outline: 'none',
                      marginBottom: '1rem'
                    }}
                    onChange={(e) => {
                      setSwapSymbol(e.target.value);
                    }}
                  >
                    {projectTokens.map((e, i) => (
                      <option value={e?.token_symbol} key={i}>
                        {e?.token_symbol} ({e?.token_price} USD)
                      </option>
                    ))}
                    {holdingTokens.map((e, i) => (
                      <option value={e?.token_symbol} key={i}>
                        {e?.token_symbol} ({e?.token_price}USD)
                      </option>
                    ))}
                  </select>
                </span>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => swapTokens(token, swapSymbol, swapAmount)}
                className="border"
                variant="contained"
                sx={{
                  borderRadius: '25px',
                  padding: '10px',
                  backgroundColor: '#76C8A8',
                  width: '100%',
                  color: 'black'
                }}
              >
                Swap
                <img
                  style={{ position: 'absolute', right: '5px' }}
                  src={require('../../../assets/images/DownloadIcon.png')}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
};

function Derivatives() {
  const params = useParams();
  const {
    PecuPrice,
    projectTokens,
    wrapTokens,
    holdingTokens,
    liquidityPools,
    derivatives,
    synthetics,
    UserInfo,
    WalletInfo,
    syntheticsList,
    hedgeTokens,
    dbtTokens,
    derivativesList,
    projectList,
    fanList,
    holdingList,
    tokenList,
    auth_token
  } = useSelector((state) => state);
  const [tokenSymbol, setTokenSymbol] = useState(params.symbol);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState();
  const [showWallet, setShowWallet] = useState(true);
  const [bid, setBid] = useState([]);
  const [ask, setAsk] = useState([]);
  const [walletAssets, setWalletAsset] = useState(null);
  const [pecuWalletActive, setPecuWalletActive] = useState(true);
  const headers = {
    Authorization: `Bearer ${auth_token}`
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [derivativesTokens, setDerivativesTokens] = useState([]);

  const [token, setToken] = useState(null);

  const [exchangeTokenPrice, setExchangeTokenPrice] = useState({
    low_24h: '36244.2',
    high_24h: '37866.1',
    current_price: '37402.4',
    change_24h: 0.022127364201056654,
    volume_24h: 0
  });

  const getTokenPercentage = (token) => {
    return token?.type === 'STNTHETICS'
      ? ((token?.currentPrice - token?.price24hour) / token?.price24hour) * 100
      : exchangeTokenPrice?.change_24h;
  };
  useEffect(() => {
    if (derivatives && derivatives.tokens) {
      setDerivativesTokens(derivatives.tokens);
    }
  }, [derivatives]);

  const fetchExchangeTokenPrice = async (symbol) => {
    const response = await axios(
      `${url}/crypto/exchangePriceData?symbol=${symbol}`
    );
    // console.log(response);
    setExchangeTokenPrice(response.data[0]);
  };

  const fetchWalletAsset = async () => {
    axios
      .get(`${url}/api/v2/wallet/balance`, { headers })
      .then((res) => {
        setWalletAsset(res.data);
        // alert(JSON.stringify(res.data));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!!auth_token) {
      fetchWalletAsset();
    }
  }, [auth_token]);

  const handleSelectionChange = (event) => {
    setTokenSymbol(event.target.value);
  };
  const fetchToken = (symbol) => {
    if (syntheticsList.includes(symbol)) {
      axios.get(`${url}/crypto/get_synthetics?symbol=${symbol}`).then((res) => {
        if (res.data && res.data.stock) {
          setToken(res.data.stock[0]);
          setLoading(false);
        }
      });
    } else if (derivativesList.includes(symbol)) {
      axios
        .get(`${url}/crypto/get_derivatives?symbol=${symbol}`)
        .then((res) => {
          if (res.data && res.data.stock) {
            setToken(res.data.stock[0]);
            setLoading(false);
          }
        });
    } else if (holdingList.includes(symbol)) {
      axios
        .get(`${url}/hootdex/get_my_tokens_project?symbol=${symbol}`)
        .then((res) => {
          if (res.data && res.data.tokens) {
            setToken(res.data.tokens[0]);
            setLoading(false);
          }
        });
    } else if (projectList.includes(symbol)) {
      axios
        .get(`${url}/wallet/get_tokens_project_by_symbol?symbol=${symbol}`)
        .then((res) => {
          setToken(res.data.token[0]);

          // setFirstChart(res.data.chart);
          setLoading(false);
        });
    } else if (fanList.includes(symbol)) {
      axios
        .get(`${url}/wallet/get_tokens_fan_by_symbol?symbol=${symbol}`)
        .then((res) => {
          setToken(res.data.token[0]);

          // setFirstChart(res.data.chart);
          setLoading(false);
        });
    } else if (hedgeTokens.includes(symbol)) {
      axios.get(`${url}/hedge/${symbol}`).then((res) => {
        if (res.data && res.data.stock) {
          setToken(res.data.stock[0]);
          setLoading(false);
        }
      });
    } else if (dbtTokens.includes(symbol)) {
      axios.get(`${url}/dat/get_dat?symbol=${symbol}`).then((res) => {
        if (res.data && res.data.stock) {
          setToken(res.data.stock[0]);
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchToken(tokenSymbol);
    fetchWalletAsset();
  }, [tokenSymbol]);
  useEffect(() => {
    if (token && token.symbol) {
      fetchExchangeTokenPrice(token?.symbol);
    }
  }, [token]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchToken(tokenSymbol);
    }, 2000);
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [tokenSymbol, token]);
  return (
    <div
      onMouseEnter={() => {
        setShowModal();
        setValue();
      }}
    >
      {loading && (
        <Backdrop sx={{ color: 'green', zIndex: 9999 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {window.screen.availWidth > 700 ? (
        <>
          <Grid className="Full_Page">
            <Grid className="Page_Grid">
              <Grid className="Grid_Column">
                <Box
                  sx={{
                    padding: '7px 5px',
                    height: { xs: 'auto', sm: '100px', md: '100px' },
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row'
                    },
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    borderRadius: '15px',
                    background: '#1C1D20'
                  }}
                >
                  <Box
                    component="select"
                    sx={{
                      height: { xs: '80px' },
                      borderRadius: '15px',
                      padding: '12px',
                      backgroundColor: '#19191A',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none',
                      outline: 'none',
                      maxWidth: '300px',
                      cursor: 'pointer',
                      width: { xs: '100%', sm: '100%', md: '20%' } // Full width on xs and sm, auto on md
                    }}
                    onChange={handleSelectionChange}
                    value={tokenSymbol}
                  >
                    {tokenList['synthetics']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['hedge']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['dbt']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['crypto_pairs']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['project_tokens']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['fan_tokens']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['holding_tokens']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      width: '80%',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '40%', md: 'auto' },
                        paddingRight: {
                          xs: '0',
                          sm: '0',
                          md: '10px',
                          lg: '10px'
                        }
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {token?.currentPrice
                          ?.toFixed(5)
                          ?.toLocaleString('en-US') ||
                          token?.token_price
                            ?.toFixed(5)
                            ?.toLocaleString('en-US')}{' '}
                        {'      '} <br></br>
                        <Box
                          component="span"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },

                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}
                        >
                          {' '}
                          Global Price
                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '40%', md: 'auto' },
                        paddingRight: {
                          xs: '0',
                          sm: '0',
                          md: '10px',
                          lg: '10px'
                        }
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {token?.price24hour
                          ?.toFixed(5)
                          ?.toLocaleString('en-US') ||
                          token?.token_price
                            ?.toFixed(5)
                            ?.toLocaleString('en-US')}{' '}
                        {'      '} <br></br>
                        <Box
                          component="span"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },

                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}
                        >
                          {' '}
                          24 Change
                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '40%', md: 'auto' },
                        paddingRight: {
                          xs: '0',
                          sm: '0',
                          md: '10px',
                          lg: '10px'
                        }
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {Math.floor(exchangeTokenPrice?.high_24h)
                          ?.toFixed(5)
                          ?.toLocaleString('en-US')}
                        <br></br>
                        <Box
                          component="span"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },

                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}
                        >
                          {' '}
                          24 High
                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '40%', md: 'auto' },
                        paddingRight: {
                          xs: '0',
                          sm: '0',
                          md: '10px',
                          lg: '10px'
                        }
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {Math.floor(exchangeTokenPrice?.low_24h)
                          ?.toFixed(5)
                          ?.toLocaleString('en-US')}
                        <br></br>
                        <Box
                          component="span"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },

                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}
                        >
                          {' '}
                          24 Low
                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                    {/* <Typography
                color={'rgb(132, 142, 156)'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '8px',
                  color: 'orange'
                }}
              >
                {Math.floor(exchangeTokenPrice?.volume_24h)
                  ?.toFixed(5)
                  ?.toLocaleString('en-US')}
                <br></br>
              </Typography> */}

                    <Box>
                      <Typography sx={TopDivStyle}>
                        {WalletInfo && walletAssets?.total?.toFixed(2)} USD
                        <br></br>
                        <Box
                          component="span"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '14px' },
                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}
                        >
                          {' '}
                          <span
                            style={{
                              color: WalletInfo ? `#686F7E` : `#F93C3C`
                            }}
                          >
                            {WalletInfo
                              ? 'Wallet Connected'
                              : 'Wallet Disconnected'}{' '}
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid className="Left_panel">
                <LeftPanel
                  tokenData={token}
                  tokenSymbol={tokenSymbol}
                  lastAsk={(ask) => setAsk(ask)}
                  lastBid={(bid) => setBid(bid)}
                />
              </Grid>
              <Grid className="Right_panel" order={{ xs: '3' }}>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between'
                  }}
                >
                  {' '}
                  <Grid
                    sx={{
                      width: { xs: '100%', sm: '100%', md: '50%' },
                      marginRight: { xs: '0', md: '20px', lg: '10px' },
                      marginLeft: { xs: '0', sm: '5px', md: '15px', lg: '20px' }
                    }}
                  >
                    <CoinInfo
                      coin={{
                        id:
                          token === 'BTC'
                            ? 'bitcoin'
                            : token == 'ETH'
                            ? 'ethereum'
                            : token == 'USDT'
                            ? 'tether'
                            : '',
                        symbol: token?.symbol || token?.token_symbol
                      }}
                      style={{
                        color: 'orange',
                        backgroundColor: '#01402b',
                        fontSize: '10px',
                        opacity: '1',
                        padding: '5px',
                        height: '30px ! important',
                        borderRadius: '13.799px'
                      }}
                    />
                    <Table token={token} />
                    {/* Make the Aboce table load More button workd */}
                  </Grid>
                  <Grid
                    sx={{
                      width: { xs: '100%', sm: '100%', md: '60%' },
                      marginRight: { xs: '0', md: '20px' },
                      marginLeft: { xs: '0', sm: '5px', md: '10px' }
                    }}
                  >
                    <SwapSystem
                      token={token}
                      PecuPrice={PecuPrice}
                      projectTokens={projectTokens}
                      holdingTokens={holdingTokens}
                      bid={bid}
                      ask={ask}
                      auth_token={auth_token}
                      fetchWalletAsset={fetchWalletAsset}
                    />{' '}
                    <Box
                      // className="border"
                      sx={{
                        backgroundColor: '#1C1D20',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '15px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        width: 'auto !important',
                        p: 3,
                        maxHeight: '330px',
                        overflow: 'auto'
                      }}
                    >
                      <Box
                        sx={{
                          width: '80%',
                          margin: '20px',
                          height: '45px',
                          borderRadius: '42.695px',
                          border: '1.265px solid #25272C',
                          backdropFilter: 'blur(26.557376861572266px)'
                        }}
                      >
                        <Button
                          onClick={() => setPecuWalletActive(true)}
                          sx={{
                            fontWeight: 'bold',
                            color: 'white',
                            width: '50%',
                            height: '100%',
                            borderRadius: '42.695px'
                          }}
                          className={pecuWalletActive ? 'activeBtn' : ''}
                        >
                          PECU Wallet
                        </Button>
                        <Button
                          onClick={() => setPecuWalletActive(false)}
                          sx={{
                            fontWeight: 'bold',
                            color: 'white',
                            width: '50%',
                            height: '100%',
                            borderRadius: '42.695px'
                          }}
                          className={pecuWalletActive ? '' : 'activeBtn'}
                        >
                          My Swaps
                        </Button>
                      </Box>
                      {pecuWalletActive ? (
                        <>
                          <TableContainer
                            component={Paper}
                            style={{
                              backgroundColor: 'rgb(43, 49, 57,0)',
                              maxHeight: '460px',
                              overflowY: 'auto'
                            }}
                          >
                            <TableHead className="">
                              <TableRow className="row">
                                <TableCell className="twhite">Asset</TableCell>
                                <TableCell className="twhite" align="left">
                                  Amount
                                </TableCell>

                                <TableCell className="twhite" align="left">
                                  Price
                                </TableCell>
                                <TableCell className="twhite" align="left">
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="tbody-table">
                              {walletAssets?.tokens?.map(
                                ({ symbol, amount, price, value }, i) => (
                                  <TableRow key={i} hover>
                                    <TableCell
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Avatar
                                        className="rounded"
                                        src={
                                          symbol === 'PECU'
                                            ? 'https://dev.hootdex.org/hootdex/images/pecuCoin.png'
                                            : `${url}/logo/tokenlogo/${
                                                symbols[symbol] ?? symbol
                                              }`
                                        }
                                        alt={symbol}
                                        style={{
                                          height: '25px',
                                          width: '25px',
                                          fontSize: '18px',
                                          marginRight: '8px'
                                        }}
                                      />{' '}
                                      {symbol}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '11px' }}>
                                      {amount?.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: '11px',
                                        color: 'green'
                                      }}
                                    >
                                      {price?.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      className={'twhite'}
                                      align="left"
                                    >
                                      <a
                                        target={'_blank'}
                                        href={`https://app.hootdex.net/${symbol}`}
                                        rel="noreferrer"
                                      >
                                        <button
                                          style={{
                                            backgroundColor: '#75c8a8',
                                            border: 'none',
                                            cursor: 'pointer',
                                            borderRadius: '10px'
                                          }}
                                        >
                                          Swap
                                        </button>
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </TableContainer>
                        </>
                      ) : (
                        <MyTradeTable />
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid></Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid className="Full_Page">
            <Grid className="Page_Grid">
              <Grid className="Grid_Column">
                <Box
                  sx={{
                    padding: '7px 5px',
                    height: { xs: 'auto', sm: '100px', md: '100px' },
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row'
                    },
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    borderRadius: '15px',
                    background: '#1C1D20'
                  }}
                >
                  <Box
                    component="select"
                    sx={{
                      height: { xs: '80px' },
                      borderRadius: '15px',
                      padding: '12px',
                      backgroundColor: '#19191A',
                      color: 'white',
                      fontWeight: 'bold',
                      border: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                      width: '90%' // Full width on xs and sm, auto on md
                    }}
                    onChange={handleSelectionChange}
                    value={tokenSymbol}
                  >
                    {tokenList['synthetics']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['crypto_pairs']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['project_tokens']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                    {tokenList['holding_tokens']?.map((each, index) => (
                      <option key={index} value={each.symbol}>
                        <small
                          style={{
                            fontSize: '13px',
                            color: 'palegreen'
                          }}
                        >
                          {each.symbol} ({each.name})
                        </small>
                      </option>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '80%',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '48%', md: 'auto' },
                        marginBottom: '30px'
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {token?.currentPrice
                          ?.toFixed(5)
                          ?.toLocaleString('en-US') ||
                          token?.token_price
                            ?.toFixed(5)
                            ?.toLocaleString('en-US')}{' '}
                        {'      '} <br></br>
                        <Box
                          component="div"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },
                            fontWeight: '500',
                            lineHeight: 'normal',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <span> Global Price</span>

                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '48%', md: 'auto' },
                        marginBottom: '30px'
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {token?.price24hour
                          ?.toFixed(5)
                          ?.toLocaleString('en-US') ||
                          token?.token_price
                            ?.toFixed(5)
                            ?.toLocaleString('en-US')}{' '}
                        {'      '} <br></br>
                        <Box
                          component="div"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },
                            fontWeight: '500',
                            lineHeight: 'normal',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {' '}
                          <span>24 Change</span>
                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '48%', md: 'auto' },
                        marginBottom: '30px'
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {Math.floor(exchangeTokenPrice?.high_24h)
                          ?.toFixed(5)
                          ?.toLocaleString('en-US')}
                        <br></br>
                        <Box
                          component="div"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },

                            fontWeight: '500',
                            lineHeight: 'normal',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {' '}
                          <span>24 High</span>
                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRight: '1px solid #25272C',
                        width: { xs: '48%', md: 'auto' },
                        marginBottom: '30px'
                      }}
                    >
                      <Typography sx={TopDivStyle}>
                        {Math.floor(exchangeTokenPrice?.low_24h)
                          ?.toFixed(5)
                          ?.toLocaleString('en-US')}
                        <br></br>
                        <Box
                          component="div"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '12px' },

                            fontWeight: '500',
                            lineHeight: 'normal',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {' '}
                          <span>24 Low</span>
                          <span
                            style={{
                              color:
                                token?.currentPrice - token?.price24hour >= 0
                                  ? `#22BD86`
                                  : `#F93C3C`
                            }}
                          >
                            {' '}
                            {token?.currentPrice - token?.price24hour > 0 ? (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            ) : token?.currentPrice - token?.price24hour < 0 ? (
                              <img
                                src={require('../../../assets/images/arrowDown.png')}
                                alt="logo"
                              />
                            ) : (
                              <img
                                src={require('../../../assets/images/arrowUp.png')}
                                alt="logo"
                              />
                            )}
                            {getTokenPercentage(token)?.toFixed(5)} %
                          </span>
                        </Box>
                      </Typography>
                    </Box>

                    <Box>
                      <Typography sx={TopDivStyle}>
                        00.00000<br></br>
                        <Box
                          component="span"
                          sx={{
                            color: '#686F7E',
                            fontSize: { xs: '9px', sm: '10px', md: '14px' },
                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}
                        >
                          {' '}
                          <span
                            style={{
                              color: WalletInfo ? `#686F7E` : `#F93C3C`
                            }}
                          >
                            {WalletInfo
                              ? 'Wallet Connected'
                              : 'Wallet Disconnected'}{' '}
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid className="Left_panel">
                <SwapSystem
                  token={token}
                  PecuPrice={PecuPrice}
                  projectTokens={projectTokens}
                  holdingTokens={holdingTokens}
                  bid={bid}
                  ask={ask}
                />{' '}
                <CoinInfo
                  coin={{
                    id:
                      token === 'BTC'
                        ? 'bitcoin'
                        : token == 'ETH'
                        ? 'ethereum'
                        : token == 'USDT'
                        ? 'tether'
                        : '',
                    symbol: token?.symbol || token?.token_symbol
                  }}
                  style={{
                    color: 'orange',
                    backgroundColor: '#01402b',
                    fontSize: '10px',
                    opacity: '1',
                    padding: '5px',
                    height: '30px ! important',
                    borderRadius: '13.799px'
                  }}
                />
                <Table token={token} />
                <Box
                  className="custom-color"
                  style={{ margin: '1rem 0 1rem 0.5rem' }}
                >
                  <span>Market Orders</span>
                </Box>
                <LeftPanel
                  tokenData={token}
                  tokenSymbol={tokenSymbol}
                  lastAsk={(ask) => setAsk(ask)}
                  lastBid={(bid) => setBid(bid)}
                />
              </Grid>
              <Grid className="Right_panel" order={{ xs: '3' }}>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between'
                  }}
                >
                  <Grid
                    sx={{
                      width: { xs: '100%', sm: '100%', md: '60%' },
                      marginRight: { xs: '0', md: '20px' },
                      marginLeft: { xs: '0', sm: '5px', md: '10px' }
                    }}
                  >
                    <Box
                      // className="border"
                      sx={{
                        backgroundColor: '#1C1D20',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '15px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        width: 'auto !important',
                        p: 3,
                        maxHeight: '600px',
                        overflow: 'auto'
                      }}
                    >
                      <Box
                        sx={{
                          width: '80%',
                          margin: '20px',
                          height: '45px',
                          borderRadius: '42.695px',
                          border: '1.265px solid #25272C',
                          backdropFilter: 'blur(26.557376861572266px)'
                        }}
                      >
                        <Button
                          onClick={() => setPecuWalletActive(true)}
                          sx={{
                            fontWeight: 'bold',
                            color: 'white',
                            width: '50%',
                            height: '100%',
                            borderRadius: '42.695px'
                          }}
                          className={pecuWalletActive ? 'activeBtn' : ''}
                        >
                          PECU Wallet
                        </Button>
                        <Button
                          onClick={() => setPecuWalletActive(false)}
                          sx={{
                            fontWeight: 'bold',
                            color: 'white',
                            width: '50%',
                            height: '100%',
                            borderRadius: '42.695px'
                          }}
                          className={pecuWalletActive ? '' : 'activeBtn'}
                        >
                          My Swaps
                        </Button>
                      </Box>
                      {pecuWalletActive ? (
                        <>
                          <TableContainer
                            component={Paper}
                            style={{
                              backgroundColor: 'rgb(43, 49, 57,0)',
                              maxHeight: '460px',
                              overflowY: 'auto'
                            }}
                          >
                            <TableHead className="">
                              <TableRow className="row">
                                <TableCell className="twhite">Asset</TableCell>
                                <TableCell className="twhite" align="left">
                                  Amount
                                </TableCell>

                                <TableCell className="twhite" align="left">
                                  Price
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className="tbody-table">
                              <TableRow hover>
                                <TableCell className="twhite" align="left">
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Avatar
                                      className="rounded"
                                      src={
                                        'https://dev.hootdex.org/hootdex/images/pecuCoin.png'
                                      }
                                      alt={'Pecu'}
                                      style={{
                                        height: '25px',
                                        width: '25px',
                                        fontSize: '18px'
                                      }}
                                    />

                                    <span
                                      style={{
                                        marginLeft: '5px',
                                        fontSize: '14px',
                                        color: 'grey',
                                        display: 'flex',
                                        flexDirection: 'column'
                                      }}
                                    >
                                      {' '}
                                      <small
                                        style={{
                                          fontSize: '13px',
                                          color: 'white'
                                        }}
                                      >
                                        {'PECU'}
                                      </small>
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell className={'twhite'} align="left">
                                  {walletAssets?.pecu}
                                </TableCell>
                                <TableCell
                                  className={'twhite'}
                                  align="left"
                                ></TableCell>
                              </TableRow>
                              {walletAssets?.tokens?.map(
                                ({ symbol, amount }, i) => (
                                  <TableRow key={i} hover>
                                    <TableCell className="twhite" align="left">
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Avatar
                                          className="rounded"
                                          src={`${url}/logo/tokenlogo/${symbols[symbol]}`}
                                          alt={logoWrapper(symbol)}
                                          style={{
                                            height: '25px',
                                            width: '25px',
                                            fontSize: '18px'
                                          }}
                                        />

                                        <span
                                          style={{
                                            marginLeft: '5px',
                                            fontSize: '14px',
                                            color: 'grey',
                                            display: 'flex',
                                            flexDirection: 'column'
                                          }}
                                        >
                                          {' '}
                                          <small
                                            style={{
                                              fontSize: '13px',
                                              color: 'white'
                                            }}
                                          >
                                            {symbol}
                                          </small>
                                        </span>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      className={'twhite'}
                                      align="left"
                                    >
                                      {amount}
                                    </TableCell>
                                    <TableCell
                                      className={'twhite'}
                                      align="left"
                                    ></TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </TableContainer>
                        </>
                      ) : (
                        <MyTradeTable />
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid>
                  {/* <Grid className="mainTab_Accordion">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: '100%',

                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    border: '1px solid rgb(43, 49, 57)',
                    marginTop: '2px'
                  }}
                >
                  {' '}
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '30px',
                      height: '30px',
                      color: 'rgb(86, 90, 105)'
                    }}
                    src={`https://dev.hootdex.org/hootdex/images/pecuCoin.png`}
                  />
                  <Tab
                    label="PECU WALLET"
                    className="headerTab"
                    style={{ color: 'whitesmoke', fontWeight: 'bold' }}
                    onClick={() => {
                      setShowWallet(true);
                    }}
                  />
                  <Tab
                    label="MY SWAPS"
                    className="headerTab"
                    style={{ color: 'whitesmoke', fontWeight: 'bold' }}
                    onClick={() => {
                      setShowWallet(false);
                    }}
                  />
                </Box>
              </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

export default React.memo(Derivatives);

import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import { useEffect } from 'react';
import { api as axios } from '../../api';
import url from '../../serverUrl';
import BuyToken from '../Modal/BuyToken';
import ConnectWallet from '../Modal/ConnectWallet';
import AddPecu from '../Modal/AddPecu';
import { useSelector } from 'react-redux';
const WalletDashboard = ({ user, pecuCoins }) => {
  const { UserInfo, WalletInfo, isAdmin } = useSelector((state) => state);
  const [totalCoins, setTotalCoins] = React.useState('');
  const [totalValue, setTotalValue] = React.useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const getMyCoins = (id) => {
    if (id) {
      axios
        .post(`${url}/wallet/getMycoins`, {
          user_id: id
        })
        .then((res) => {
          const { total_coins, value } = res.data;
          setTotalCoins(total_coins);
          setTotalValue(value);
        });
    }
  };

  useEffect(() => {
    if (WalletInfo && WalletInfo.uid) {
      getMyCoins(WalletInfo.uid);
    }
  }, [WalletInfo]);

  return (
    <Box
      className="rounded shadow-light"
      sx={{
        width: '95%',
        ml: '2.5%',
        mt: 3,
        mb: 1,
        height: '100vh',
        pt: 1
      }}
    >
      <h2 style={{ color: '#fff', textAlign: 'center', fontWeight: 'bold' }}>
        Wallet Dashboard
      </h2>
      <Grid container spacing={3} sx={{ p: 1 }}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className="border dashboard-card" sx={{ p: 2 }}>
            <div
              style={{
                color: 'white',
                wordWrap: 'break-word'
              }}
            >
              <div
                className="rounded center-width tUpper"
                style={{ backgroundColor: '#01402b' }}
              >
                <h3>Connected Wallet</h3>
              </div>
              <>
                {WalletInfo?.uid ? (
                  <>
                    {' '}
                    <img
                      src={
                        'https://dev.hootdex.org/hootdex/images/pecuCoin.png'
                      }
                      alt="pecu wallet"
                    />
                    <p>PECU WALLET</p>
                  </>
                ) : (
                  'Wallet Disconnected'
                )}
              </>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className="border dashboard-card" sx={{ p: 2 }}>
            <div
              style={{
                color: 'white',
                wordWrap: 'break-word'
              }}
            >
              <div
                className="rounded center-width tUpper"
                style={{ backgroundColor: '#01402b' }}
              >
                <h3>Current Holdings</h3>
              </div>
              {WalletInfo && WalletInfo.uid ? (
                <>
                  <p>Pecu Coins: {totalCoins}</p>
                  <br></br>
                  <p>
                    Total Value: ${' '}
                    {parseFloat(totalValue).toLocaleString('en-US')}
                  </p>
                </>
              ) : (
                'Wallet Disconnected'
              )}
            </div>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4}>
            <Paper
              style={{
                textAlign: "center",
                backgroundColor: "grey",
                cursor: "pointer",
              }}
              className="border"
              // onClick={() => wallet?.uid && navigate("/create-token")}
            >
              <div
                style={{
                  color: "white",
                  wordWrap: "break-word",
                }}
              >
                <div
                  className="rounded center-width tUpper"
                  style={{ backgroundColor: "#01402b" }}
                >
                  <h3>Buy New</h3>
                </div>
                <p className="fontS22 tlower">Token</p>
              </div>
            </Paper>
          </Grid> */}

        <Grid item xs={12} md={6} lg={4}>
          <Paper className="border dashboard-card" sx={{ p: 2 }}>
            <div
              style={{
                color: 'white',
                wordWrap: 'break-word'
              }}
            >
              <div
                className="rounded center-width tUpper"
                style={{ backgroundColor: '#01402b' }}
              >
                <h3>Add Pecu Coins</h3>
              </div>
              {WalletInfo && WalletInfo.uid ? (
                <Button
                  variant="outlined"
                  sx={{
                    color: 'white',
                    textTransform: 'capitalize',
                    m: 1
                  }}
                  onClick={() => setOpen(true)}
                >
                  Add Pecu
                </Button>
              ) : (
                'Wallet Disconnected'
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <AddPecu setOpen={setOpen} open={open} wallet={WalletInfo} />
    </Box>
  );
};

export default WalletDashboard;

// api.js

import axios from 'axios';
import url from './serverUrl';

// Create a new Axios instance with default configuration
export const api = axios.create({
  baseURL: url, // Replace with your API base URL
  headers: {
    'x-secret-key':
      '3af7d905b99ace11a03b3250f037612d8429e3084985242f227766418946eac4' // Replace with your secret key
  }
});

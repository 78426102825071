import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';
import { Box } from '@mui/system';
import TokenRequest from '../Modal/TokenRequest';
import { api as axios } from '../../api';
import { useEffect, useState } from 'react';
import url from '../../serverUrl';
import LineCharts from '../Graphs/LineChart';
import CreatePool from '../../screens/CreatePool';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MyProfile from './MyProfile';
import CreatePoolHolding from '../../screens/createPoolHolding';
import UpdatePoolHolding from '../../screens/updatePoolHolding';
import CreateSyntheticHolding from '../../screens/createSyntheticPool';
import UpdateToken from '../../screens/updateToken';
import { Link } from 'react-router-dom';
function DashboardContent({ user }) {
  const [pendingToken, setPendingToken] = useState([]);
  const [totalCoins, setTotalCoins] = useState('');
  const [totalValue, setTotalValue] = useState('');
  const [synthetic, setSynthetic] = useState([]);
  const [derivatives, setDerivative] = useState([]);
  const [projectTokens, setProjectTokens] = useState([]);
  const [totalToken, setTotalToken] = useState([]);
  const [createPool, setCreatePool] = useState(false);
  const [holdingToken, setHoldingToken] = useState([]);
  const [dat, setDat] = useState([]);
  const [poolToken, setPoolToken] = useState({});
  const [pools, setPools] = useState([]);
  const [rawSynth, setRawSynth] = useState([]);
  const username = user.username;
  const fetchTokens = () => {
    if (username) {
      axios.get(`${url}/hootdex/all-token/Pending`).then((res) => {
        setPendingToken(res.data.reverse());
      });
    }
  };
  const fetchProjectTokens = () => {
    axios.get(`${url}/wallet/get_all_tokens_project`).then((res) => {
      setProjectTokens(res.data.tokens.filter((e) => e.hasPool == 0));
    });
  };

  const fetchSyntheticTokens = () => {
    axios
      .get(`${url}/crypto/get_all_synthetics?page=1&perPage=20`)
      .then((res) => {
        setRawSynth(res.data.tokens);
        setSynthetic(res.data.tokens.filter((e) => e.hasPool == 0));
      });
  };

  const fetchDerivativesTokens = () => {
    axios
      .get(`${url}/crypto/get_all_derivatives?page=1&perPage=150`)
      .then((res) => {
        setDerivative(res.data.tokens);
      });
  };

  const fetchHoldingTokens = () => {
    axios.get(`${url}/wallet/get_all_tokens_holding`).then((res) => {
      setHoldingToken(res.data.tokens);
    });
  };
  const fetchDat = () => {
    axios.get(`${url}/dat?page=1&perPage=100`).then((res) => {
      setDat(res.data.tokens);
    });
  };
  const gettotalTokens = () => {
    axios.get(`${url}/hootdex/all-token-reqs`).then((res) => {
      setTotalToken(res.data.reverse());
    });
  };

  const getMyCoins = (id) => {
    if (id) {
      axios
        .post(`${url}/wallet/getMycoins`, {
          user_id: id
        })
        .then((res) => {
          const { total_coins, value } = res.data;
          setTotalCoins(total_coins);
          setTotalValue(value);
        });
    }
  };
  const fetchPools = () => {
    axios
      .get(`${url}/hootdex/liquidity-pool-info-all?page=1&perPage=217`)
      .then((res) => {
        setPools(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    let user_id = user.user_id;
    fetchTokens();
    getMyCoins(user_id);
    gettotalTokens();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);
  useEffect(() => {
    fetchProjectTokens();
    fetchHoldingTokens();
    fetchSyntheticTokens();
    fetchDerivativesTokens();
    fetchPools();
    fetchDat();
  }, []);
  const [tab, setTab] = useState('Dashboard');
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          mt: 1,
          width: '95%',
          ml: '2.5%',
          mb: 1
        }}
      >
        <ListItemButton
          className={`${tab === 'Dashboard' && 'shadow'}`}
          sx={{
            ':hover': {
              bgcolor: '#1a1b1f', // theme.palette.primary.main
              color: 'white'
            },
            color: 'white',
            backgroundColor: '#00071a',
            borderRadius: '20px',
            m: 1,
            border: '1px solid #091e17'
          }}
          onClick={() => setTab('Dashboard')}
        >
          <ListItemIcon>
            <DashboardIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText
            sx={{ display: { xs: 'none', sm: 'block' } }}
            primary="Dashboard"
          />
        </ListItemButton>
        <ListItemButton
          className={`${tab === 'Profile' && 'shadow'}`}
          sx={{
            ':hover': {
              bgcolor: '#1a1b1f', // theme.palette.primary.main
              color: 'white'
            },
            color: 'white',
            backgroundColor: '#00071a',
            borderRadius: '20px',
            m: 1,
            border: '1px solid #091e17'
          }}
          onClick={() => setTab('Profile')}
        >
          <ListItemIcon>
            <AccountCircleIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText
            sx={{ display: { xs: 'none', sm: 'block' } }}
            primary="My Profile"
          />
        </ListItemButton>
      </Box>
      {tab === 'Dashboard' && (
        <>
          <Box
            className="rounded shadow"
            sx={{
              padding: 1,
              width: '95%',
              ml: '2.5%',
              mt: 1,
              pb: 2
            }}
          >
            {' '}
            <Grid
              container
              spacing={5}
              sx={{ textTransform: 'uppercase', p: 5 }}
            >
              <Grid item xs={12} md={6} lg={3} mt={5}>
                {/* dashboard left */}
                <Grid sx={{ mt: 3 }}>
                  <Paper
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#00071a'
                    }}
                    className="border tShadow"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Issued Tokens</h4>
                      </div>
                      <p className="fontS22">{totalToken.length}</p>
                    </div>
                  </Paper>
                </Grid>
                <Grid sx={{ mt: 3 }}>
                  <Paper
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#00071a'
                    }}
                    className="border"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Total Value</h4>
                      </div>
                      <p className="fontS22">
                        $
                        {totalToken.reduce(
                          (a, b) => a + b.investementAmount || 0,
                          0
                        )}
                      </p>
                    </div>
                  </Paper>
                </Grid>
                <Grid sx={{ mt: 3 }}>
                  <Paper
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#00071a'
                    }}
                    className="border"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Total Tokens</h4>
                      </div>
                      <p className="fontS18">
                        {totalToken.reduce((a, b) => a + b.totalToken, 0)}
                      </p>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              {/* dashboard middle */}
              <Grid item xs={4} md={8} lg={6}>
                <Paper
                  sx={{
                    backgroundColor: '#00071a'
                  }}
                  className="border"
                >
                  <Typography
                    sx={{ textAlign: 'center', color: 'white', mt: 1 }}
                    component="p"
                    variant="h5"
                  >
                    Total Holdings
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <LineCharts user={user} />
                  </div>
                </Paper>
              </Grid>

              {/* dashboard right */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'space-evenly'
                }}
              >
                <Grid
                  sx={{ maxHeight: '400px', overflowY: 'auto', flex: 1, mt: 3 }}
                >
                  {' '}
                  <Paper
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#00071a',
                      maxHeight: '50vh',
                      overflowY: 'scroll'
                    }}
                    className="border hide-scrollbar"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Update Synthetic Tokens</h4>
                      </div>
                    </div>
                    {[...rawSynth, ...derivatives].map((each, index) => (
                      <div
                        style={{
                          border: '0.5px solid green',
                          margin: '5px 0px 5px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '1rem',
                          borderRadius: '8px'
                        }}
                        key={index}
                      >
                        <p style={{ color: 'orange', fontWeight: 'bold' }}>
                          {each?.identitySymbol}
                        </p>
                        <button
                          style={{
                            padding: '2px',
                            color: 'green',
                            borderRadius: '5px',
                            outline: 'none',
                            cursor: 'pointer'
                          }}
                          onClick={(e) => {
                            setPoolToken(each);
                            setTab('update-token');
                          }}
                        >
                          Update Now
                        </button>

                        {/* <p style={{ color: 'white' }}>{each.token_name}</p>
            <p style={{ color: 'white' }}>{each.token_name}</p> */}
                      </div>
                    ))}
                  </Paper>
                </Grid>
                <Grid sx={{ mt: 3, flex: 1 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#00071a',
                      maxHeight: '50vh',
                      overflowY: 'scroll'
                    }}
                    className="border hide-scrollbar"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Create Liquidity Pool</h4>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: 'white'
                      }}
                    >
                      <small>Derivatives</small>
                      {/* <small>Amount</small>
                  <small>Status</small> */}
                    </div>
                    {derivatives.map((each, index) => (
                      <div
                        style={{
                          border: '0.5px solid green',
                          margin: '5px 0px 5px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '1rem',
                          borderRadius: '8px'
                        }}
                      >
                        <p style={{ color: 'orange', fontWeight: 'bold' }}>
                          {each?.identitySymbol}
                        </p>
                        <button
                          style={{
                            padding: '2px',
                            color: 'green',
                            borderRadius: '5px',
                            outline: 'none',
                            cursor: 'pointer'
                          }}
                          onClick={(e) => {
                            setPoolToken(each);
                            setTab('create-synthetic-holding');
                          }}
                        >
                          Create Pool
                        </button>

                        {/* <p style={{ color: 'white' }}>{each.token_name}</p>
                    <p style={{ color: 'white' }}>{each.token_name}</p> */}
                      </div>
                    ))}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: 'white'
                      }}
                    >
                      <small>Synthetic</small>
                      {/* <small>Amount</small>
                  <small>Status</small> */}
                    </div>
                    {synthetic.map((each, index) => (
                      <div
                        style={{
                          border: '0.5px solid green',
                          margin: '5px 0px 5px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '1rem',
                          borderRadius: '8px'
                        }}
                      >
                        <p style={{ color: 'orange', fontWeight: 'bold' }}>
                          {each?.identitySymbol}
                        </p>
                        {each.hasPool === 0 ? (
                          <button
                            style={{
                              padding: '2px',
                              color: 'green',
                              borderRadius: '5px',
                              outline: 'none',
                              cursor: 'pointer'
                            }}
                            onClick={(e) => {
                              setPoolToken(each);
                              setTab('create-synthetic-holding');
                            }}
                          >
                            Create Pool
                          </button>
                        ) : (
                          <button
                            style={{
                              padding: '2px',
                              color: 'green',
                              borderRadius: '5px',
                              outline: 'none',
                              cursor: 'pointer'
                            }}
                            disabled
                          >
                            Already Created
                          </button>
                        )}

                        {/* <p style={{ color: 'white' }}>{each.token_name}</p>
                    <p style={{ color: 'white' }}>{each.token_name}</p> */}
                      </div>
                    ))}
                    {holdingToken.map((each, index) => (
                      <div
                        style={{
                          border: '0.5px solid green',
                          margin: '5px 0px 5px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '1rem',
                          borderRadius: '8px'
                        }}
                      >
                        <p style={{ color: 'orange', fontWeight: 'bold' }}>
                          {each?.token_symbol}
                        </p>
                        {each.hasPool === 0 ? (
                          <button
                            style={{
                              padding: '2px',
                              color: 'green',
                              borderRadius: '5px',
                              outline: 'none',
                              cursor: 'pointer'
                            }}
                            onClick={(e) => {
                              setPoolToken(each);
                              setTab('create-pool-holding');
                            }}
                          >
                            Create Pool
                          </button>
                        ) : (
                          <button
                            style={{
                              padding: '2px',
                              color: 'green',
                              borderRadius: '5px',
                              outline: 'none',
                              cursor: 'pointer'
                            }}
                            disabled
                          >
                            Already Created
                          </button>
                        )}

                        {/* <p style={{ color: 'white' }}>{each.token_name}</p>
                    <p style={{ color: 'white' }}>{each.token_name}</p> */}
                      </div>
                    ))}
                    {projectTokens.map((each, index) => (
                      <div
                        style={{
                          border: '0.5px solid green',
                          margin: '5px 0px 5px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '1rem',
                          borderRadius: '8px'
                        }}
                      >
                        <p style={{ color: 'orange', fontWeight: 'bold' }}>
                          {each?.token_symbol}
                        </p>
                        {each.hasPool === 0 ? (
                          <button
                            style={{
                              padding: '2px',
                              color: 'green',
                              borderRadius: '5px',
                              outline: 'none',
                              cursor: 'pointer'
                            }}
                            onClick={(e) => {
                              setPoolToken(each);
                              setTab('create-pool');
                            }}
                          >
                            Create Pool
                          </button>
                        ) : (
                          <button
                            style={{
                              padding: '2px',
                              color: 'green',
                              borderRadius: '5px',
                              outline: 'none',
                              cursor: 'pointer'
                            }}
                            disabled
                          >
                            Already Created
                          </button>
                        )}

                        {/* <p style={{ color: 'white' }}>{each.token_name}</p>
                    <p style={{ color: 'white' }}>{each.token_name}</p> */}
                      </div>
                    ))}
                  </Paper>
                </Grid>
                <Grid sx={{ mt: 3, flex: 1 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#00071a',
                      maxHeight: '50vh',
                      overflowY: 'scroll'
                    }}
                    className="border hide-scrollbar"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Update Liquidity Pool</h4>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: 'white'
                      }}
                    >
                      {/* <small>Name</small> */}
                      {/* <small>Amount</small>
                  <small>Status</small> */}
                    </div>

                    {pools.map((each, index) => (
                      <div
                        style={{
                          border: '0.5px solid green',
                          margin: '5px 0px 5px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '1rem',
                          borderRadius: '8px'
                        }}
                      >
                        <p style={{ color: 'orange', fontWeight: 'bold' }}>
                          {each?.project_name}
                        </p>
                        <button
                          style={{
                            padding: '2px',
                            color: 'green',
                            borderRadius: '5px',
                            outline: 'none',
                            cursor: 'pointer'
                          }}
                          onClick={(e) => {
                            setPoolToken(each);
                            setTab('update-pool-holding');
                          }}
                        >
                          Update Now
                        </button>

                        {/* <p style={{ color: 'white' }}>{each.token_name}</p>
                    <p style={{ color: 'white' }}>{each.token_name}</p> */}
                      </div>
                    ))}
                  </Paper>
                </Grid>
                <Grid sx={{ mt: 3, flex: 1 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#00071a',
                      maxHeight: '50vh',
                      overflowY: 'scroll'
                    }}
                    className="border hide-scrollbar"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Digital Basket Token</h4>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: 'white'
                      }}
                    ></div>
                    <Link to="/createDBT">
                      <button
                        style={{
                          width: '100%',
                          backgroundColor: 'orange',
                          color: 'white',
                          padding: '10px',
                          border: 'none',
                          borderRadius: '3px',
                          cursor: 'pointer',
                          margin: '5px'
                        }}
                      >
                        Create DBT
                      </button>
                    </Link>
                    {dat.map((each, index) => (
                      <div
                        style={{
                          border: '0.5px solid green',
                          margin: '5px 0px 5px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '1rem',
                          borderRadius: '8px'
                        }}
                      >
                        <p style={{ color: 'orange', fontWeight: 'bold' }}>
                          {each?.symbol}
                        </p>
                        <button
                          style={{
                            padding: '2px',
                            color: 'green',
                            borderRadius: '5px',
                            outline: 'none',
                            cursor: 'pointer'
                          }}
                          onClick={(e) => {
                            setPoolToken(each);
                            setTab('update-pool-holding');
                          }}
                        >
                          Edit
                        </button>

                        {/* <p style={{ color: 'white' }}>{each.token_name}</p>
                    <p style={{ color: 'white' }}>{each.token_name}</p> */}
                      </div>
                    ))}
                  </Paper>
                </Grid>
              </div>

              {/* <Grid item xs={12} md={8} lg={3} mt={5}>
                <Grid sx={{ mt: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#00071a',
                      maxHeight: '50vh',
                      overflowY: 'scroll'
                    }}
                    className="border hide-scrollbar"
                  >
                    <div
                      style={{
                        color: 'white',
                        wordWrap: 'break-word'
                      }}
                    >
                      <div
                        className="rounded center-width tUpper"
                        style={{ backgroundColor: '#002945' }}
                      >
                        <h4>Pool Request</h4>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: 'white'
                      }}
                    >
                      <small>Name</small>
                      <small>Amount</small>
                      <small>Status</small>
                    </div>
                    {pendingToken.map((each, index) => (
                      <TokenRequest
                        fetchTokens={fetchTokens}
                        user={user}
                        each={each}
                        index={index}
                        key={index}
                      />
                    ))}
                    {!pendingToken.length && (
                      <div className="bg2 twhite">
                        <small>No Pending Request</small>
                      </div>
                    )}
                  </Paper>
                </Grid>
                <Grid sx={{ mt: 3 }}></Grid>
              </Grid> */}
            </Grid>
          </Box>
          {/* second row */}
          <Box
            className="rounded shadow"
            sx={{
              padding: 1,
              width: '95%',
              ml: '2.5%',
              mt: 3,
              mb: 1
            }}
          >
            <Grid container spacing={5} sx={{ p: 1 }}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Paper
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#00071a'
                  }}
                  className="border"
                >
                  <div
                    style={{
                      color: 'white',
                      wordWrap: 'break-word'
                    }}
                  >
                    <div
                      className="rounded center-width tUpper"
                      style={{ backgroundColor: '#002945' }}
                    >
                      <h4>Total XMG</h4>
                    </div>
                    <p className="fontS22">$ 3,000,000,000</p>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Paper
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#00071a'
                  }}
                  className="border"
                >
                  <div
                    style={{
                      color: 'white',
                      wordWrap: 'break-word'
                    }}
                  >
                    <div
                      className="rounded center-width tUpper"
                      style={{ backgroundColor: '#002945' }}
                    >
                      <h4>Total Pecu Coin</h4>
                    </div>
                    <p className="fontS22">{totalCoins}</p>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Paper
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#00071a'
                  }}
                  className="border"
                >
                  <div
                    style={{
                      color: 'white',
                      wordWrap: 'break-word'
                    }}
                  >
                    <div
                      className="rounded center-width tUpper"
                      style={{ backgroundColor: '#002945' }}
                    >
                      <h4>Total PECU Value</h4>
                    </div>
                    <p className="fontS22">$ {totalValue}</p>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Paper
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#00071a'
                  }}
                  className="border"
                >
                  <div
                    style={{
                      color: 'white',
                      wordWrap: 'break-word'
                    }}
                  >
                    <div
                      className="rounded center-width tUpper"
                      style={{ backgroundColor: '#002945' }}
                    >
                      <h4>Current Balance</h4>
                    </div>
                    <p className="fontS22">
                      ${' '}
                      {parseInt(
                        totalToken.reduce(
                          (a, b) => a + b.investementAmount || 0,
                          0
                        )
                      ) +
                        parseInt(totalValue) +
                        3000000000}
                    </p>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {tab === 'create-pool' && (
        <CreatePool
          token={poolToken}
          closeMe={() => {
            setTab('Dashboard');
          }}
        />
      )}
      {tab === 'create-pool-holding' && (
        <CreatePoolHolding
          token={poolToken}
          closeMe={() => {
            setTab('Dashboard');
          }}
        />
      )}
      {tab === 'update-pool-holding' && (
        <UpdatePoolHolding
          token={poolToken}
          closeMe={() => {
            setTab('Dashboard');
          }}
        />
      )}
      {tab === 'create-synthetic-holding' && (
        <CreateSyntheticHolding
          token={poolToken}
          closeMe={() => {
            setTab('Dashboard');
          }}
        />
      )}
      {tab === 'Profile' && <MyProfile user={user} />}
      {tab === 'update-token' && (
        <UpdateToken token={poolToken} setTab={setTab} />
      )}
    </>
  );
}

export default function Tier1Dashboard({ user }) {
  return <DashboardContent user={user} />;
}

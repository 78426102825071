import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { api as axios } from '../../../api';
import url from '../../../serverUrl';
import { Box } from '@material-ui/core';
const styles = (theme) => ({
  root: {
    width: '400px'
  },
  table: {
    backgroundColor: '#1a1b1f'
  },
  tableRow: {
    '&$hover:hover': {
      backgroundColor: '#31343e'
    }
  },
  tableCell: {
    '$hover:hover &': {
      color: 'green'
    }
  },
  hover: {}
});
const convertDate = (inputDateString) => {
  const inputDate = new Date(inputDateString);
  const outputDate = `${inputDate.getUTCDate()}/${
    inputDate.getUTCMonth() + 1
  }/${inputDate.getUTCFullYear()} ${inputDate.getUTCHours()}:${inputDate.getUTCMinutes()}:${inputDate.getUTCSeconds()} GMT`;
  return outputDate;
};
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);
  // timestamp.setHours(timestamp.getHours() + 8);
  return timestamp;
}

function DenseTable({ classes, token }) {
  const [bidData, setBidData] = useState([]);
  const [marketTrade, setMarketTrade] = useState([]);
  const [symbol, setSymbol] = useState('');
  const fetchTrade = (symbol) => {
    axios
      .get(`${url}/crypto/marketTrade?symbol=${symbol}`)
      .then((res) => {
        setMarketTrade(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (
      token &&
      (token.symbol !== symbol?.symbol ||
        token.token_symbol !== symbol.token_symbol)
    ) {
      let symbolNew = token.identitySymbol ?? token.token_symbol;
      setSymbol(token);
      fetchTrade(symbolNew);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const intervalId = setInterval(() => {
        let symbolNew = token?.identitySymbol ?? token?.token_symbol;

        fetchTrade(symbolNew);
      }, 2000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [token]);

  return (
    <Box
      style={{
        backgroundColor: '#19191A',
        width: '100%',
        marginTop: '1rem',
        marginLeft: '8px',
        maxHeight: window.screen.availWidth > 700 ? '770px' : '500px',
        overflow: 'auto'
      }}
    >
      <Box className="custom-color">
        <span>
          Market Trades ({symbol?.identitySymbol || symbol?.token_symbol}){' '}
        </span>
      </Box>
      <Table
        className={classes.table}
        stickyHeader
        style={{
          marginTop: '1rem',
          borderRadius: '15px'
        }}
      >
        <TableBody className="tbody-table">
          <TableRow
            hover
            classes={{ hover: classes.hover }}
            className={classes.tableRow}
          >
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Price'}
            </TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Amount'}
            </TableCell>

            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'DATE & TIME'}
            </TableCell>
          </TableRow>

          {marketTrade &&
            marketTrade.length > 0 &&
            marketTrade.map((row) => (
              <>
                <TableRow
                  hover
                  key={row._id}
                  classes={{ hover: classes.hover }}
                  className={classes.tableRow}
                >
                  <TableCell
                    style={{
                      fontSize: '14px',
                      color: row?.type === 'BUY' ? '#22BD86' : '#F93C3C'
                    }}
                  >
                    {row.price?.toFixed(5)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '11px'
                    }}
                  >
                    {Math.abs(row?.amount)?.toFixed(5)}
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: '11px',
                      wordBreak: 'break'
                    }}
                  >
                    {convertDate(
                      getFormattedTime(row?.timestamp)?.toUTCString()
                    )}
                  </TableCell>
                </TableRow>
              </>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
}

DenseTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DenseTable);

import React from 'react';
import Box from '@mui/material/Box';
import './Derivatives.css';

function HeaderTab() {
  return (
    <Box
      sx={{
        width: '75pxpx',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <button className="optionSetting">ChatHive</button>
    </Box>
  );
}
export default React.memo(HeaderTab);

import Tier0Dashboard from '../components/dashboard/Tier0Dashboard';
import Tier1Dashboard from '../components/dashboard/Tier1Dashboard';
import Tier2Dashboard from '../components/dashboard/Tier2Dashboard';
import React, { useState } from 'react';
import Users from './Users';
import { Button, Divider } from '@mui/material';
import { useSelector } from 'react-redux';

const DashboardIndex = ({ pecuCoins }) => {
  const { UserInfo } = useSelector((state) => state);
  const [tier, setTier] = useState(UserInfo.tier || 2);
  const [tab, setTab] = useState(0);

  const switchTier = () => {
    if (tier < 2) {
      setTier(tier + 1);
    }
    if (tier === 2) {
      setTier(0);
    }
  };

  return (
    <>
      <Divider color="black" />
      {(UserInfo.tier === 0 || UserInfo.tier === 1) && (
        <Button
          onClick={() => {
            switchTier();
          }}
          variant="outlined"
          sx={{
            color: 'white',
            textTransform: 'capitalize',
            m: 1
          }}
        >
          Tier {tier}
        </Button>
      )}

      {tier === 0 && tab === 0 && (
        <Tier0Dashboard user={UserInfo} pecuCoins={pecuCoins} />
      )}
      {tier === 1 && tab === 0 && (
        <Tier1Dashboard user={UserInfo} pecuCoins={pecuCoins} />
      )}
      {tier === 2 && tab === 0 && (
        <Tier2Dashboard user={UserInfo} pecuCoins={pecuCoins} />
      )}
      {tab === 'users' && <Users user={UserInfo} />}
    </>
  );
};

export default DashboardIndex;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import url from '../../../serverUrl';
import { api, api as axios } from '../../../api';
import { useState } from 'react';
import { useEffect } from 'react';
import symbols from '../../../symbolsMapper';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { logoWrapper } from '../../../utils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { AddAlertRounded } from '@mui/icons-material';
export default function MyTradeTable({ trade }) {
  const { WalletInfo } = useSelector((state) => state);
  const [marketTrade, setMarketTrade] = useState([]);
  const [user, setUser] = useState();
  const { auth_token } = useSelector((state) => state);
  const [searchTerm, setSearchTerm] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [rawtransactions, setRawTransactions] = useState([]);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const headers = {
    Authorization: `Bearer ${auth_token}`
  };
  const fetchTransactions = () => {
    api
      .get('/api/v2/wallet/transactions', { headers })
      .then((res) => {
        setTransactions(res.data);
      })
      .catch((err) => console.log(err));
    api
      .get('/api/v2/wallet/rawtransactions', { headers })
      .then((res) => {
        setRawTransactions(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!!auth_token) {
      fetchTransactions();
    }
  }, [auth_token]);
  useEffect(() => {
    if (WalletInfo && WalletInfo !== 'null' && WalletInfo !== null) {
      setUser(WalletInfo);
    }
  }, []);

  const getMyTrades = (id) => {
    if (id) {
      axios
        .get(`${url}/crypto/myTrade?user=${id}`)
        .then(async (res) => {
          if (res.data) {
            setMarketTrade(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const deleteBidOrder = (id) => {
    api
      .delete(`${url}/api/v2/wallet/bidOrder/${id}`, { headers })
      .then((res) => {
        fetchTransactions();
        alert(JSON.stringify(res.data));
      })
      .catch((err) => alert(JSON.stringify(err)));
  };
  const deleteAskOrder = (id) => {
    api
      .delete(`${url}/api/v2/wallet/askOrder/${id}`, { headers })
      .then((res) => {
        fetchTransactions();
        alert(JSON.stringify(res.data));
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  // useEffect(() => {
  //   if (WalletInfo && WalletInfo.pub_key) {
  //     getMyTrades(WalletInfo?.pub_key);
  //   }
  // }, [WalletInfo]);

  return (
    <TableContainer
      component={Paper}
      style={{
        backgroundColor: 'rgb(43, 49, 57,0)',
        maxHeight: '460px',
        overflowY: 'auto'
      }}
    >
      <Table stickyHeader size="small">
        <TableBody className="tbody-table">
          <TableRow hover>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Asset'}
            </TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Amount'}
            </TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Price'}
            </TableCell>

            {/* <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Total'}
            </TableCell> */}

            {/* <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Timestamp'}
            </TableCell> */}
          </TableRow>
          {/* <h3 style={{ margin: '1rem', color: 'white' }}>
            {' '}
            Executed Swap Orders
          </h3> */}
          <h5 style={{ margin: '1rem', color: 'grey' }}>All Orders</h5>
          {rawtransactions?.map((row) => (
            <>
              <TableRow hover key={row._id}>
                <TableCell
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={
                      row?.symbol === 'PECU'
                        ? 'https://dev.hootdex.org/hootdex/images/pecuCoin.png'
                        : `${url}/logo/tokenlogo/${
                            symbols[row?.symbol] ?? row?.symbol
                          }`
                    }
                    alt={row?.symbol}
                    style={{
                      height: '25px',
                      width: '25px',
                      fontSize: '18px',
                      marginRight: '8px'
                    }}
                  />{' '}
                  {row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.amount)?.toFixed(5)}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                {/* <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {row.timestamp}
                  <br></br>
                </TableCell> */}
              </TableRow>
            </>
          ))}
          <h5 style={{ margin: '1rem', color: 'grey' }}>Open Buy Orders</h5>
          {transactions?.pending?.bidOrderBook.map((row) => (
            <>
              <TableRow hover key={row.pos}>
                <TableCell
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={
                      row?.symbol === 'PECU'
                        ? 'https://dev.hootdex.org/hootdex/images/pecuCoin.png'
                        : `${url}/logo/tokenlogo/${
                            symbols[row?.symbol] ?? row?.symbol
                          }`
                    }
                    alt={row?.symbol}
                    style={{
                      height: '25px',
                      width: '25px',
                      fontSize: '18px',
                      marginRight: '8px'
                    }}
                  />{' '}
                  {row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.quantity)?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                <DeleteOutlineIcon
                  style={{ color: 'red', cursor: 'pointer' }}
                  fontSize="small"
                  onClick={() => deleteBidOrder(row._id)}
                />
                {/* <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {row.timestamp}
                  <br></br>
                </TableCell> */}
                {/* <TableCell>
                  <button
                    style={{
                      fontSize: '11px',
                      backgroundColor: 'red',
                      margin: '1rem',
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      deleteOrder(row._id);
                    }}
                  >
                    {' '}
                    {'Cancel'}
                  </button>
                </TableCell> */}
              </TableRow>
            </>
          ))}

          <h5 style={{ margin: '1rem', color: 'grey' }}>Open Sell Orders</h5>
          {transactions?.pending?.askOrderBook?.map((row) => (
            <>
              <TableRow hover key={row.pos}>
                <TableCell
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={
                      row?.symbol === 'PECU'
                        ? 'https://dev.hootdex.org/hootdex/images/pecuCoin.png'
                        : `${url}/logo/tokenlogo/${
                            symbols[row?.symbol] ?? row?.symbol
                          }`
                    }
                    alt={row?.symbol}
                    style={{
                      height: '25px',
                      width: '25px',
                      fontSize: '18px',
                      marginRight: '8px'
                    }}
                  />{' '}
                  {row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.quantity)?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                <DeleteOutlineIcon
                  fontSize="small"
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => deleteAskOrder(row._id)}
                />
                {/* <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {new Date(row.timestamp)?.toLocaleString()}
                  <br></br>
                </TableCell> */}
              </TableRow>
            </>
          ))}
          <h5 style={{ margin: '1rem', color: 'grey' }}>Executed Orders</h5>
          {transactions?.executed?.map((row) => (
            <>
              <TableRow hover key={row.pos}>
                <TableCell
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Avatar
                    className="rounded"
                    src={
                      row?.symbol === 'PECU'
                        ? 'https://dev.hootdex.org/hootdex/images/pecuCoin.png'
                        : `${url}/logo/tokenlogo/${
                            symbols[row?.symbol] ?? row?.symbol
                          }`
                    }
                    alt={row?.symbol}
                    style={{
                      height: '25px',
                      width: '25px',
                      fontSize: '18px',
                      marginRight: '8px'
                    }}
                  />{' '}
                  {row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.amount)?.toFixed(5)}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                {/* <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {row.timestamp}
                  <br></br>
                </TableCell> */}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const navTabsList = [
  {
    label: 'Market',
    value: 'https://www.hootdex.com/getting-started/'
  },
  {
    label: 'Swap',
    value: '#',
    
  },

  {
    label: 'Options',
    value: '#'
  },
  {
    label: 'EduCenter',
    value: 'Liquidity Pools'
  },
  {
    label: 'Tools',
    value: '#'
  },
  {
    label: 'Feed',
    value: 'Liquidity Pools'
  },

 
];
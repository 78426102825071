const styles = {
    width: {
      xs: '75px',
      sm: '105px',
      md: '85px',
      lg: '90px',
    },
    height: {
      xs: '75px',
      sm: '105px',
      md: '75px',
      lg: '90px',
    },
  };
  

export const ParaSyle = {
  color: '#FFF',
  'textAlign': 'center',
  'fontFamily': 'Poppins',
  'fontSize': '24px',
  'fontStyle': 'normal',
  'fontWeight': 500,
  'lineHeight': '150%' /* 36px */
};

export const CIrcleDiv = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '0',
  width: { xs: '75px', sm: '85px', md: '85px', lg: '90px' },
  height: { xs: '75px', sm: '85px', md: '75px', lg: '90px' },
  margin: { lg: '0 0 90px 0' },
  borderRadius: '50%',
  backgroundColor: '#1C2221',
  '@media (min-width: 601px) and (max-width: 960px)': {
    width: styles.width.sm,
    height: styles.height.sm,
    top:"20px",
  },
  filter:
    'drop-shadow(0px 3px 6px rgba(38, 135, 98, 0.10)) drop-shadow(0px 11px 11px rgba(38, 135, 98, 0.09)) drop-shadow(0px 25px 15px rgba(38, 135, 98, 0.05)) drop-shadow(0px 44px 18px rgba(38, 135, 98, 0.01)) drop-shadow(0px 69px 19px rgba(38, 135, 98, 0.00))'
};

export const IconDiv = {
  width: {xs:"25px",md:"50px"},
  height: {xs:"25px",md:"50px"},
  backgroundColor: 'white',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex'
};
